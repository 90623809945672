<template>
  <generic-dialog
    :show="show"
    :title="'Візити клієнта'"
    @close="$emit('close')"
  >
    <editable-table
      :columns="columns"
      :items="customerVisits"
      :loading="loading"
      @add="handleAdd"
      @edit="handleEdit"
      @delete="handleDelete"
    />
  </generic-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import GenericDialog from '../../genericDialog/GenericDialog.vue';
import EditableTable from '../../editableTable/EditableTable.vue';
import customerVisitsApi from '@/api/customerVisits';
import { getISODate } from '@/lib/utils';

const REQUIRED_RULE = v => !!v || 'Вимагане поле';

const CUSTOMER_VISITS_COLUMNS = [
  {
    text: 'Опис',
    value: 'description',
    field: { type: 'textArea', rules: [REQUIRED_RULE] },
  },
  {
    text: 'Дата',
    value: 'date',
    field: {
      type: 'datePicker',
      maxDate: getISODate(new Date()),
      rules: [REQUIRED_RULE],
    },
    width: 120,
  },
  { text: 'Час', value: 'time', field: { type: 'timePicker' }, width: 75 },
  {
    text: 'Адмін',
    value: 'admin',
    field: { type: 'textField', rules: [REQUIRED_RULE] },
  },
  {
    text: 'Створено',
    value: 'created',
    width: 200,
  },
  {
    text: 'Відредаговано',
    value: 'edited',
    width: 200,
  },
];

export default {
  name: 'CustomerVisitsDialog',
  components: {
    GenericDialog,
    EditableTable,
  },

  props: {
    show: Boolean,
  },

  data: () => ({
    columns: CUSTOMER_VISITS_COLUMNS,
    customerVisits: [],
    loading: false,
  }),

  computed: {
    currentChatId() {
      return this.$store.state.chat.currentChatId;
    },
    ...mapGetters('support', {
      getSupportByAzureId: 'getSupportByAzureId',
    }),
  },

  watch: {
    async show(value) {
      if (value) {
        await this.fetchData();
      } else {
        this.customerVisits = [];
      }
    },
  },

  methods: {
    getSupportActionDescription(supportAzureId, timestamp) {
      const { nickname, email } = this.getSupportByAzureId(supportAzureId);

      if (!email || !timestamp) {
        return '';
      }

      const actionDate = getISODate(new Date(timestamp));

      return `${nickname || email} ${actionDate}`;
    },

    transformData(data) {
      return data.map(
        ({
          createdAt,
          createdBy,
          lastEditedAt,
          lastEditedBy,
          ...customerVisitData
        }) => ({
          created: this.getSupportActionDescription(createdBy, createdAt),
          edited: this.getSupportActionDescription(lastEditedBy, lastEditedAt),
          ...customerVisitData,
        })
      );
    },

    async fetchData() {
      try {
        this.loading = true;
        const { data } = await customerVisitsApi.getAll({
          chatId: this.currentChatId,
        });
        this.customerVisits = this.transformData(data);
      } catch (e) {
        this.customerVisits = [];
      } finally {
        this.loading = false;
      }
    },

    async handleAdd(item) {
      try {
        const result = await customerVisitsApi.add({
          chatId: this.currentChatId,
          customerVisit: item,
        });
        await this.fetchData();
        return result;
      } catch (e) {
        console.log('error adding new customer visit');
        console.log(e);
      }
    },

    async handleEdit(item) {
      try {
        const result = await customerVisitsApi.edit({
          chatId: this.currentChatId,
          customerVisitWithId: item,
        });
        await this.fetchData();
        return result;
      } catch (e) {
        console.log('error editing new customer visit');
        console.log(e);
      }
    },

    async handleDelete(customerVisitId) {
      try {
        const result = await customerVisitsApi.remove({
          chatId: this.currentChatId,
          customerVisitId,
        });
        await this.fetchData();
        return result;
      } catch (e) {
        console.log('error deleting new customer visit');
        console.log(e);
      }
    },
  },
};
</script>
