const state = {
  api: {
    get: {
      notifications: '',
    },
  },

  notifications: [],
  links: {},
};

const getters = {};

const mutations = {
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload.notifications;
    state.links = payload.links;
  },

  SET_NOTIFICATION(state, notification) {
    state.notifications.push(notification);
  },

  SET_MORE_NOTIFICATIONS(state, payload) {
    state.notifications.push(...payload.notifications);
    state.links = payload.links;
  },

  REMOVE_NOTIFICATION(state, { notificationId }) {
    // for (let i = 0; i < state.notifications.length; i++) {
    //     if (state.notifications[i].id == notificationId) {
    //         state.notifications.splice(i, 1);
    //         break;
    //     }
    // }
  },
};

const actions = {
  set_notification({ commit }, notification) {
    commit('SET_NOTIFICATION', notification);
  },
  remove_notification({ commit }, payload) {
    commit('REMOVE_NOTIFICATION', payload);
  },

  //Api calls
  set_notifications({ commit, state }) {
    // return new Promise((resolve, reject) => {
    //     let url = location.origin + state.api.get.notifications;
    //
    //     this.$http.get(url)
    //         .then(res => {
    //             commit('SET_NOTIFICATIONS', {
    //                 notifications: res.data.data,
    //                 links: res.data.links
    //             });
    //             resolve(res)
    //         })
    //         .catch(err => reject(err));
    // });
  },

  set_more_notifications({ commit, state }) {
    // return new Promise((resolve, reject) => {
    //     let url = state.links.next;
    //
    //     this.$http.get(url)
    //         .then(res => {
    //             commit('SET_MORE_NOTIFICATIONS', {
    //                 notifications: res.data.data,
    //                 links: res.data.links
    //             });
    //             resolve(res)
    //         })
    //         .catch(err => reject(err));
    // });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
