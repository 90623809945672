<template>
  <v-navigation-drawer absolute permanent width="80">
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          elevation="0"
          small
          color="blue"
          dark
          class="d-block text-center mx-auto mt-4 mb-1"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="sequenceSort" inset color="blue"></v-switch>
          </v-list-item-action>
          <v-list-item-title>Сортування (початок черги)</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="darkMode" inset color="blue"></v-switch>
          </v-list-item-action>
          <v-list-item-title>Dark mode</v-list-item-title>
        </v-list-item>

        <features-menu />

        <v-list-item @click="logoutUser" link>
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Вийти</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-divider class="mx-3 my-4"></v-divider>
    <button
      v-for="folder in regularFolders"
      @click.prevent="getChats(folder)"
      class="d-block text-center mx-auto mb-4"
    >
      <v-badge
        overlap
        :content="counts[folder.name]"
        :color="counts[folder.name] > 0 ? 'pink' : 'transparent'"
      >
        <v-avatar
          class="mb-1"
          :color="currentFolder === folder.name ? 'blue darken-1' : 'grey'"
          size="32"
        >
          <v-icon v-if="folder.icon" dark size="medium">{{
            folder.icon
          }}</v-icon>
          <span v-else class="white--text text-body-2">{{
            folder.label.charAt(0).toUpperCase()
          }}</span>
        </v-avatar>
      </v-badge>
      <span class="d-block text-caption grey--text">{{ folder.label }}</span>
    </button>

    <v-divider class="mx-3 my-4"></v-divider>
    <button
      v-for="folder in departmentFolders"
      @click.prevent="getChats(folder)"
      class="d-block text-center mx-auto mb-4"
    >
      <v-badge
        overlap
        :content="counts[folder.name]"
        :color="counts[folder.name] > 0 ? 'blue' : 'transparent'"
      >
        <v-avatar
          class="mb-1"
          :color="currentFolder === folder.name ? 'blue darken-1' : 'grey'"
          size="32"
        >
          <v-icon dark size="medium">{{ folder.icon }}</v-icon>
        </v-avatar>
      </v-badge>
      <span class="d-block text-caption grey--text">{{ folder.label }}</span>
    </button>
  </v-navigation-drawer>
</template>

<script>
import FeaturesMenu from '@/components/common/FeaturesMenu';
import msalAuth from '../../../lib/msalAuth';
export default {
  name: 'FoldersBar',
  components: {
    FeaturesMenu,
  },
  computed: {
    darkMode: {
      get() {
        return this.$vuetify.theme.dark;
      },

      set(value) {
        window.localStorage.setItem('darkMode', value);
        this.$vuetify.theme.dark = value;
      },
    },

    sequenceSort: {
      get() {
        return this.$store.state.chat.sequenceSort;
      },

      set(val) {
        this.$store.commit('chat/SET_SEQUENCE_SORT', val, { root: true });
      },
    },

    currentFolder: {
      get() {
        return this.$store.state.chat.currentFolder;
      },

      set(folderName) {
        this.$store.commit('chat/SET_CURRENT_FOLDER', folderName, {
          root: true,
        });
      },
    },

    regularFolders() {
      return this.$store.getters['chat/regularFolders'];
    },

    departmentFolders() {
      return this.$store.getters['chat/departmentFolders'];
    },

    counts() {
      return this.$store.state.chat.counts;
    },
  },

  methods: {
    async logoutUser() {
      const msalInstance = await msalAuth.createMsalInstance();

      msalInstance.logoutPopup({}).then(() => {
        this.$store.commit('auth/REMOVE_CREDENTIALS');
        location.href = '/';
      });
      // this.$router.push('/'); too many changes for this, no bugs if refresh page
    },

    getChats(folder) {
      this.currentFolder = folder.name;
      // this.$store.dispatch('chat/get_chats', {}, {root: true});
    },
  },
};
</script>

<style scoped>
button {
  outline: none !important;
}
</style>
