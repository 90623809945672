const axios = require('axios');

const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const routes = {
  post: {
    login: `${origin}/${prefix}/login`,
    adminLogin: `${origin}/${prefix}/admins/login`,
  },
  //Must be like this
  // get: {
  //     generateNewVerificationCode: `${origin}/${prefix}/authentication/generate-new-verification-code`,
  //     checkRefreshToken: `${origin}/${prefix}/authentication/check-refresh-token`,
  // },

  // post: {
  //     registration: `${origin}/${prefix}/authentication/registration`,
  //     login: `${origin}/${prefix}/authentication/login`,
  //     checkVerificationCode: `${origin}/${prefix}/authentication/check-verification-code`,
  //     passwordReset: `${origin}/${prefix}/authentication/password-reset`,
  //     passwordRecovery: `${origin}/${prefix}/authentication/password-recovery`,
  // },
};

exports.getRoutes = () => {
  return routes;
};

// exports.register = (credentials) => {
//     const url = routes.post.registration;
//     return axios.post(url, credentials);
// };

exports.login = ({ token }) => {
  const url = routes.post.login;
  return axios.post(url, token);
};

exports.adminLogin = ({ login, password }) => {
  const url = routes.post.adminLogin;
  const data = { login, password };
  return axios.post(url, data);
};
// exports.checkVerificationCode = (credentials) => {
//     const url = routes.post.checkVerificationCode;
//     return axios.post(url, credentials);
// };
//
// exports.resetPassword = (credentials) => {
//     const url = routes.post.passwordReset;
//     return axios.post(url, credentials);
// };
//
// exports.recoverPassword = (token, params) => {
//     const url = routes.post.passwordRecovery + `?resetToken=${token}`;
//     let formData = new FormData();
//
//     Object.keys(params).forEach(param => {
//         if (params[param] !== null && params[param] !== '') formData.append(param, params[param]);
//     });
//
//     return axios.post(url, formData);
// };
