import axios from 'axios';

const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const routes = {
  get: {
    search: `${origin}/${prefix}/customer-records/search`,
  },
};

const search = (query = {}) => {
  const params = Object.keys(query).length
    ? `?${new URLSearchParams(query).toString()}`
    : '';
  const url = routes.get.search + params;
  return axios.get(url);
};

export default { search };
