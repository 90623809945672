<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredClientVotes"
      :loading="loading"
      loading-text="Загружаю Голоси"
      sort-by="createdAt"
      class="elevation-1"
      :items-per-page="15"
      :footer-props="{
        'items-per-page-options': [15, 30, 50, -1],
      }"
      height="calc(100vh - 123px)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Клієнтські голосування</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-radio-group
            v-model="inputs.category"
            class="mt-0"
            row
            dense
            hide-details
          >
            <v-radio label="Всі" color="blue" value="all"></v-radio>
            <v-radio label="Хороші" color="blue" value="good"></v-radio>
            <v-radio label="Погані" color="blue" value="bad"></v-radio>
          </v-radio-group>
        </v-toolbar>
      </template>

      <template v-slot:item.chats="{ item }">
        <v-avatar color="teal" size="32" class="mr-3">
          <img
            v-if="item.chats[0].avatar"
            :src="blobContainerUrl + item.chats[0].avatar"
          />
          <span v-else class="white--text">{{
            item.chats[0].from.first_name.charAt(0).toUpperCase()
          }}</span>
        </v-avatar>
        <span>{{ getName(item.chats[0]) }}</span>
      </template>

      <template v-slot:item.supports="{ item }">
        <span>{{
          item.supports[0] ? item.supports[0].nickname : 'Не знайшли сапорта'
        }}</span>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <span>{{ formatDate(item.createdAt) }}</span>
      </template>

      <template v-slot:item.vote="{ item }">
        <div v-if="item.vote === 'good'">
          <v-icon color="green" size="medium" class="mr-1"
            >mdi-thumb-up-outline</v-icon
          >
        </div>

        <div v-else-if="item.vote === 'bad'">
          <v-icon color="red" size="medium" class="mr-1"
            >mdi-thumb-down-outline</v-icon
          >
        </div>

        <div v-else>Нема голосу</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn v-if="item.vote" @click="deleteClientVote(item._id)" icon small>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import clientVotesApi from '@/api/admin/clientVotes';
import { getObjectIndexByKeyValue, formatDate, formatTime } from '@/lib/utils';
import { BLOB_CONTAINER_URL } from '@/lib/const';

export default {
  name: 'ClientVotes',
  data() {
    return {
      blobContainerUrl: BLOB_CONTAINER_URL,

      clientVotes: [],
      dialog: false,
      delete: false,
      loading: false,

      headers: [
        { text: "Ім'я/Нік", value: 'chats' },
        { text: 'Менеджер', value: 'supports' },
        { text: 'Створено', value: 'createdAt', align: 'center' },
        { text: 'Голос', value: 'vote', align: 'center' },
        { text: 'Дії', value: 'actions', align: 'right', sortable: false },
      ],

      inputs: {
        category: 'all',
      },
    };
  },

  computed: {
    filteredClientVotes() {
      let clientVotes = [];

      switch (this.inputs.category) {
        case 'all':
          clientVotes = this.clientVotes.filter(cv => cv.vote);
          break;

        case 'good':
          clientVotes = this.clientVotes.filter(cv => cv.vote === 'good');
          break;

        case 'bad':
          clientVotes = this.clientVotes.filter(cv => cv.vote === 'bad');
          break;
      }

      return clientVotes;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    getName(chat) {
      let name = `${chat.from.first_name || ''} ${chat.from.last_name || ''}`;
      if (name.trim().length === 0) name = chat.from.username;

      return name.trim() || 'No name';
    },

    getStatusColor(status) {
      let color = '';

      switch (status) {
        case 'pending':
          color = 'orange';
          break;

        case 'sent':
          color = 'green';
          break;

        case 'error':
          color = 'red';
          break;
      }

      return color;
    },

    formatDate(date) {
      return formatDate(date) + ' | ' + formatTime(date);
    },

    initialize() {
      this.loading = true;

      (async () => {
        await this.getClientVotes();
        this.loading = false;
      })();
    },

    async getClientVotes() {
      try {
        const type = 'serviceRating';
        const limit = 2000;
        let skip = 0;
        let prevChatLength = 0;

        while (true) {
          let clientVotesRequest = await clientVotesApi.getAll({
            type,
            limit,
            skip,
          });
          this.clientVotes = [...this.clientVotes, ...clientVotesRequest.data];

          skip = this.clientVotes.length;

          if (prevChatLength === this.clientVotes.length) break;

          prevChatLength = this.clientVotes.length;
        }
      } catch (e) {
        console.error('Не зміг прогрузити clientVotes =(', e);
      }
    },

    addClientVote(clientVote) {
      this.clientVotes.push(clientVote);
    },

    editClientVote(clientVote) {
      this.$refs['edit-dialog'].show = true;
      this.$refs['edit-dialog'].clientVote = clientVote;
    },

    async deleteClientVote(clientVoteId) {
      if (!this.delete) {
        this.delete = true;
        setTimeout(() => {
          this.delete = false;
        }, 500);
        return;
      }

      await clientVotesApi
        .delete(clientVoteId)
        .then(res => {
          let clientVoteIndex = getObjectIndexByKeyValue(
            this.clientVotes,
            clientVoteId,
            '_id'
          );
          this.clientVotes.splice(clientVoteIndex, 1);
        })
        .catch(e => {
          console.error(e);
        });
    },
  },
};
</script>

<style scoped></style>
