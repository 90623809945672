const axios = require('axios');

const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const routes = {
  get: {
    messages: `${origin}/${prefix}/messages/{chatId}`,
    count: `${origin}/${prefix}/messages/count`,
    unreadCount: `${origin}/${prefix}/messages/{chatId}/unread-count`,
    search: `${origin}/${prefix}/messages/search`,
    searchChat: `${origin}/${prefix}/messages/{chatId}/search`,
    replyWaitTime: `${origin}/${prefix}/messages/replyWaitTime`,
  },

  post: {
    message: `${origin}/${prefix}/messages/{chatId}`,
    forward: `${origin}/${prefix}/messages/{chatId}/forward`,
    photo: `${origin}/${prefix}/messages/{chatId}/photo`,
    document: `${origin}/${prefix}/messages/{chatId}/document`,
    color: `${origin}/${prefix}/messages/{messageId}/color`,
    comment: `${origin}/${prefix}/messages/{messageId}/comment`,
    templateWithFiles: `${origin}/${prefix}/messages/{chatId}/templateWithFiles`,
  },

  put: {
    message: `${origin}/${prefix}/messages/{messageId}`,
    media: `${origin}/${prefix}/messages/{messageId}/media`,
    caption: `${origin}/${prefix}/messages/{messageId}/caption`,
  },

  delete: {
    message: `${origin}/${prefix}/messages/{messageId}`,
  },
};

exports.getRoutes = () => {
  return routes;
};

exports.getAll = (chatId, query = {}) => {
  let params = Object.keys(query).length
    ? `?${new URLSearchParams(query).toString()}`
    : '';
  const url = routes.get.messages.replace('{chatId}', chatId) + params;

  return axios.get(url);
};

exports.getCount = (query = {}) => {
  let params = Object.keys(query).length
    ? `?${new URLSearchParams(query).toString()}`
    : '';

  const url = routes.get.count + params;
  return axios.get(url);
};

exports.search = (query = {}) => {
  let params = Object.keys(query).length
    ? `?${new URLSearchParams(query).toString()}`
    : '';
  const url = routes.get.search + params;
  return axios.get(url);
};

exports.searchChat = (chatId, query = {}) => {
  const urlWithChatId = routes.get.searchChat.replace('{chatId}', chatId);
  const params = Object.keys(query).length
    ? `?${new URLSearchParams(query).toString()}`
    : '';
  const url = urlWithChatId + params;
  return axios.get(url);
};

exports.getUnreadCount = chatId => {
  const url = routes.get.unreadCount.replace('{chatId}', chatId);
  return axios.get(url);
};

exports.create = (chatId, message, replyToMessageId) => {
  const url = routes.post.message.replace('{chatId}', chatId);
  let data = {
    message,
    ...(replyToMessageId ? { replyToMessageId } : {}),
  };
  return axios.post(url, data);
};

exports.forward = (chatId, fromChatId, messageId) => {
  const url = routes.post.forward.replace('{chatId}', chatId);
  let data = {
    chatId,
    fromChatId,
    messageId,
  };

  return axios.post(url, data);
};

exports.sendPhoto = (chatId, photo, caption, replyToMessageId) => {
  const url = routes.post.photo.replace('{chatId}', chatId);

  let formData = new FormData();
  formData.append('photo', photo);
  if (caption) formData.append('caption', caption);
  if (replyToMessageId) formData.append('replyToMessageId', replyToMessageId);

  return axios.post(url, formData);
};

exports.sendDocument = (chatId, document, caption, replyToMessageId) => {
  const url = routes.post.document.replace('{chatId}', chatId);

  let formData = new FormData();
  formData.append('document', document);
  if (caption) formData.append('caption', caption);
  if (replyToMessageId) formData.append('replyToMessageId', replyToMessageId);

  return axios.post(url, formData);
};

exports.editMessageCaption = (messageId, chatId, text) => {
  const url = routes.put.caption.replace('{messageId}', messageId);
  let data = {
    chatId,
    messageId,
    text,
  };

  return axios.put(url, data);
};

exports.editMessageMedia = (messageId, chatId, mediaType, file, caption) => {
  const url = routes.put.media.replace('{messageId}', messageId);

  let formData = new FormData();
  formData.append('file', file);
  formData.append('chatId', chatId);
  formData.append('type', mediaType);
  formData.append('caption', caption);

  return axios.put(url, formData);
};

exports.color = (messageId, chatId, color) => {
  const url = routes.post.color.replace('{messageId}', messageId);
  let data = {
    chatId,
    messageId,
    color,
  };

  return axios.post(url, data);
};

exports.comment = (messageId, chatId, comment) => {
  const url = routes.post.comment.replace('{messageId}', messageId);
  let data = {
    chatId,
    messageId,
    comment,
  };

  return axios.post(url, data);
};

exports.edit = (messageId, chatId, text) => {
  const url = routes.put.message.replace('{messageId}', messageId);
  let data = {
    chatId,
    messageId,
    text,
  };

  return axios.put(url, data);
};

exports.delete = (messageId, chatId) => {
  const url = routes.delete.message.replace('{messageId}', messageId);

  const config = {
    data: { chatId },
  };

  return axios.delete(url, config);
};

exports.sendTemplateWithFiles = (chatId, payload) => {
  const url = routes.post.templateWithFiles.replace('{chatId}', chatId);

  return axios.post(url, payload);
};

exports.getMessageReplyWaitTime = () => {
  const url = routes.get.replyWaitTime;

  return axios.get(url);
};
