export const translations = {
  save: 'Зберегти',
  undoTitle: 'Відмінити останню операцію',
  redoTitle: 'Повторити останню операцію',
  showImageTitle: 'Показати оригінальну картинку',
  resetOperations: 'Усунути всі операції',
  adjustTab: 'Підігнати',
  annotateTab: 'Малювати',
  original: 'Оригінал',
  custom: 'Custom',
  landscape: 'Пейзаж',
  portrait: 'Портрет',
  ellipse: 'Еліпс',
  ellipseTool: 'Еліпс',
  rotateTool: 'Обернути',
  flipX: 'Перевернути по осі Х',
  flipY: 'Перевернути по осі Y',
  unFlipX: 'Перевернути по осі Х',
  unFlipY: 'Перевернути по осі Y',
  textTool: 'Текст',
  textAlignment: 'Вирівняти текст',
  textSpacings: 'Текстовий інтервал',
  opacity: 'Непрозорість',
  position: 'Позиція',
  shadow: 'Тінь',
  stroke: 'Товщина',
  imageTool: 'Картинка',
  addImage: '+ Додати картинку',
  rectangleTool: 'Прямокутник',
  cornerRadius: 'Кутовий радіус',
  polygonTool: 'Багатокутник',
  penTool: 'Ручка',
  lineTool: 'Лінія',
  arrowTool: 'Стрілка',
  sides: 'Сторони',
};
