<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="-1"
    :loading="loading"
    sort-by="calories"
    hide-default-footer
  >
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
    </template>
    <template v-slot:no-data>Немає даних</template>
    <template v-slot:footer>
      <v-toolbar flat
        ><v-btn
          color="primary"
          dark
          class="mb-2"
          @click="showItemDialog = true"
        >
          Додати новий візит
        </v-btn>
      </v-toolbar>
      <delete-dialog
        :show="showDeleteDialog"
        @closeDelete="closeDelete"
        @deleteItemConfirm="deleteItemConfirm"
      />
      <item-dialog
        :title="formTitle"
        :columns="columns"
        :item="editedItem"
        :show="showItemDialog"
        @change="onItemChange"
        @close="showItemDialog = false"
        @save="save"
      />
    </template>
  </v-data-table>
</template>

<script>
import DeleteDialog from './DeleteDialog.vue';
import ItemDialog from './itemDialog/ItemDialog.vue';

export default {
  name: 'EditableTable',

  components: {
    DeleteDialog,
    ItemDialog,
  },

  props: {
    columns: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      showItemDialog: false,
      showDeleteDialog: false,

      editedIndex: -1,
      editedItem: this.getDefaultItem(this.columns),
      defaultItem: this.getDefaultItem(this.columns),

      headers: [
        ...this.columns.map(({ text, value, width }) => ({
          text,
          value,
          width,
        })),
        { value: 'actions', width: 72, sortable: false },
      ],
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Додати візит' : 'Редагувати візит';
    },
  },

  watch: {
    showItemDialog(val) {
      val || this.close();
    },
    showDeleteDialog(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    getDefaultItem(columns) {
      return columns.reduce((acc, column) => {
        if (column.hasOwnProperty('field') && column.hasOwnProperty('value')) {
          acc[column['value']] = '';
        }
        return acc;
      }, {});
    },

    onItemChange(item) {
      this.editedItem = item;
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showItemDialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showDeleteDialog = true;
    },

    deleteItemConfirm() {
      const { id } = this.editedItem;
      this.$emit('delete', id);
      this.closeDelete();
    },

    close() {
      this.showItemDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.showDeleteDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.$emit('edit', this.editedItem);
      } else {
        this.$emit('add', this.editedItem);
      }
      this.close();
    },
  },
};
</script>
