import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'viewerjs/dist/viewer.css';
import axios from 'axios';
import msal from './lib/msalAuth';

Vue.config.productionTip = false;

// Function to clear session data
const clearSessionData = store => {
  store.commit('auth/REMOVE_CREDENTIALS');
  sessionStorage.clear();
};

// Axios interceptor for requests
axios.interceptors.request.use(
  async config => {
    try {
      const msalInstance = await msal.createMsalInstance();
      const account = msalInstance.getAllAccounts()[0];

      const { accessToken: token } = await msalInstance.acquireTokenSilent({
        account,
      });

      config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
      clearSessionData(store);
      location.href = process.env.VUE_APP_REDIRECT_URI;
    }

    return config;
  },
  null,
  { synchronous: false }
);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
