<template>
  <v-card
    @contextmenu.prevent="openMenu($event, message)"
    class="message"
    :class="[
      isSelected ? 'white--text' : '',
      isSelected ? (darkMode ? 'blue darken-4' : 'green lighten-2') : '',
      message.color !== 'none' ? 'message--' + message.color : '',
      message.supportId !== null ? 'sender' : '',
      message.messageId < 0 ? 'history' : '',
      darkMode ? 'dark' : 'light',
    ]"
    flat
  >
    <div @click="toggleSelected">
      <v-menu
        v-if="message.comment"
        open-on-hover
        top
        max-width="320px"
        :left="message.supportId !== null"
        :right="message.supportId === null"
        :nudge-left="message.supportId !== null ? 40 : 0"
        :nudge-right="message.supportId !== null ? 0 : 40"
        nudge-top="40"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="commentMessage"
            fab
            dark
            x-small
            elevation="0"
            color="blue"
            class="message__badge"
            :class="[message.supportId !== null ? 'sender' : '']"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-comment-text-outline</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-text>
            <span style="white-space: pre-wrap">{{
              message.comment.length <= 255
                ? message.comment
                : message.comment.slice(0, 255) + '...'
            }}</span>
          </v-card-text>
        </v-card>
      </v-menu>

      <div v-if="message.forwardFrom" style="margin-bottom: 0.25rem">
        <div style="font-size: 14px; font-weight: 500">
          Переслано від {{ message.forwardFrom.senderName }}
        </div>
      </div>

      <div class="message__contents">
        <div
          v-if="message.replyToMessage"
          style="display: flex; align-items: center; margin-bottom: 0.5rem"
        >
          <div
            style="
              width: 2px;
              height: 40px;
              background: cornflowerblue;
              margin-right: 0.5rem;
            "
          ></div>
          <template v-if="message.replyToMessage.isRemoved">
            <div style="font-size: 14px">Повідомлення видалене</div>
          </template>

          <template v-else>
            <button
              @click="searchRepliedMessage($event, message)"
              type="button"
              class="d-flex text-left flex-grow-1"
            >
              <div
                v-if="message.replyToMessage.document"
                style="margin-right: 0.5rem"
              >
                <v-icon dense color="blue">mdi-file-outline</v-icon>
              </div>
              <div
                v-if="message.replyToMessage.photo"
                style="margin-right: 0.5rem"
              >
                <div
                  style="
                    width: 2rem;
                    height: 2rem;
                    background-size: cover;
                    background-position: center;
                    border-radius: 4px;
                  "
                  :style="{
                    'background-image': `url(${
                      blobContainerUrl + message.chatId
                    }/${message.replyToMessage.photo.fileName})`,
                  }"
                ></div>
              </div>

              <div>
                <div
                  class="text-truncate"
                  style="
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1;
                    max-width: 120px;
                  "
                >
                  {{
                    message.replyToMessage.isBot
                      ? 'Менеджер'
                      : `${currentChat.from.first_name} ${currentChat.from.last_name}`
                  }}
                </div>
                <div
                  v-if="replyToMessageText"
                  class="text-truncate"
                  style="font-size: 14px; max-width: 120px"
                >
                  {{ replyToMessageText }}
                </div>
              </div>
            </button>
          </template>
        </div>

        <div v-if="message.document" class="message__content">
          <a
            :href="blobContainerUrl + message.document.fileName"
            target="_blank"
            class="message__link"
            style="display: flex; align-items: center"
          >
            <div>
              <img
                v-if="
                  isRequiredFile(message.document.originalName, ['jpg', 'png'])
                "
                :src="blobContainerUrl + message.document.fileName"
                style="
                  width: 5rem;
                  max-height: 10rem;
                  height: auto;
                  padding: 0px 5px;
                "
                alt="Preview of image"
              />

              <div
                v-else-if="
                  isRequiredFile(message.document.originalName, ['mp4', 'mp3'])
                "
                style="max-width: 240px"
                class="message__content"
              >
                <video
                  autoplay
                  loop
                  muted
                  playsinline
                  style="max-width: 250px; height: 320px"
                >
                  <source
                    :src="blobContainerUrl + message.document.fileName"
                    type="video/mp4"
                  />
                  Your browser does not support the video element.
                </video>
              </div>

              <v-icon
                dense
                :color="
                  isSelected
                    ? 'white'
                    : $vuetify.theme.dark && message.supportId === null
                    ? 'white'
                    : 'black'
                "
                >mdi-file-outline</v-icon
              >
              <span
                class="d-inline-block ml-1 text-truncate"
                :class="[
                  isSelected ? 'white--text' : 'black--text',
                  {
                    'white--text':
                      $vuetify.theme.dark && message.supportId === null,
                  },
                ]"
                style="max-width: 400px; vertical-align: middle"
              >
                {{ message.document.originalName }}
              </span>
              <p>Click to DOWNLOAD</p>
            </div>
          </a>
        </div>

        <div
          v-if="message.photo"
          style="max-width: 240px; width: 240px"
          class="message__content"
        >
          <div
            @click="viewImage(blobContainerUrl + message.photo.fileName)"
            :style="{
              'background-image': `url(${
                blobContainerUrl + message.photo.fileName
              })`,
            }"
            class="viewer__image"
          ></div>
        </div>

        <div v-if="message.voice" class="message__content">
          <voice-message
            :source="`${blobContainerUrl}${message.voice.fileName}`"
            :duration="message.voice.duration"
          />
        </div>

        <div
          v-if="message.videoNote"
          style="max-width: 240px"
          class="message__content"
        >
          <video controls style="max-width: 100%; height: 320px">
            <source
              :src="blobContainerUrl + message.videoNote.fileName"
              :type="message.videoNote.mimeType"
            />
            Your browser does not support the video element.
          </video>
        </div>

        <div
          v-if="message.video"
          style="max-width: 240px"
          class="message__content"
        >
          <video controls style="max-width: 100%; height: 320px">
            <source
              :src="blobContainerUrl + message.video.fileName"
              :type="message.video.mimeType"
            />
            Your browser does not support the video element.
          </video>
        </div>

        <div
          v-if="message.text"
          class="message__content"
          v-html="messageText"
        ></div>

        <div v-if="hasSelectedMessages" class="message__select-shield"></div>
      </div>

      <div
        style="
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: 0.25rem;
        "
      >
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
          "
        >
          <div v-if="message.supportId" class="message__support mr-1">
            <span v-if="supportsById.has(message.supportId)">{{
              supportsById.get(message.supportId).nickname
            }}</span>
            <span v-else>Видалеsний</span>
          </div>

          <div
            class="message__date mr-1"
            :class="[isSelected ? 'white--text' : '']"
          >
            <span>({{ formatDate(Math.round(message.createdAt)) }})</span>
            {{ formatTime(Math.round(message.createdAt)) }}
          </div>
          <div v-if="message.editedAt" class="message__edited">(edited)</div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import VoiceMessage from './fileSection/VoiceMessage.vue';
import { BLOB_CONTAINER_URL } from '@/lib/const';

export default {
  name: 'Message',
  components: {
    VoiceMessage,
  },

  props: {
    message: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      $viewer: null,

      options: {
        title: false,
        navbar: false,
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 1,
          reset: 1,
          rotateLeft: 1,
          rotateRight: 1,
        },
      },
      loading: false,
      blobContainerUrl: BLOB_CONTAINER_URL,
      delete: false,
    };
  },

  computed: {
    darkMode: {
      get() {
        return this.$vuetify.theme.dark;
      },

      set(value) {
        window.localStorage.setItem('darkMode', value);
        this.$vuetify.theme.dark = value;
      },
    },

    supportsById() {
      return new Map(
        this.$store.state.support.supports.map(i => [i.azureProviderId, i])
      );
    },

    selectedMessages() {
      return this.$store.state.chat.selectedMessages;
    },

    hasSelectedMessages() {
      return this.$store.getters['chat/hasSelectedMessages'];
    },

    isSelected() {
      return (
        this.$store.getters['chat/selectedMessagesIds'].indexOf(
          this.message._id
        ) >= 0
      );
    },

    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },

    replyToMessageText() {
      if (this.message.replyToMessage.text) {
        return this.message.replyToMessage.text;
      } else if (this.message.replyToMessage.document) {
        return 'Документ';
      } else if (this.message.replyToMessage.photo) {
        return 'Фото';
      } else if (this.message.replyToMessage.voice) {
        return 'Голосове повідомлення';
      } else if (this.message.replyToMessage.videoNote) {
        return 'Відео повідомлення';
      } else if (this.message.replyToMessage.video) {
        return 'Відео';
      }

      return '';
    },

    messageText() {
      if (!this.message.text) return '';

      const urlExpression =
        /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

      const replacer = match => {
        let link = match.indexOf('http') !== 0 ? `https://${match}` : match;
        return `<a href="${link}" class="message__link" target="_blank">${match}</a>`;
      };

      return this.message.text.replace(urlExpression, replacer);
    },
  },

  methods: {
    isRequiredFile(filename, affordableExtensions) {
      return affordableExtensions.some(extension =>
        filename.toLowerCase().endsWith(extension)
      );
    },

    getImageSize(h, w) {
      let aspectRatio = h > w ? h / w : w / h;

      return {
        h: h / aspectRatio,
        w: w / aspectRatio,
      };
    },

    viewImage(src) {
      this.$emit('view-img', src);
    },

    commentMessage() {
      this.$store.commit(
        'ui/SET_CURRENT_DIALOG',
        {
          name: 'commentDialog',
          data: {
            message: this.message,
          },
        },
        { root: true }
      );
    },

    toggleSelected() {
      if (
        !this.hasSelectedMessages ||
        this.message?.meta?.includes('departmentsChange')
      )
        return;

      if (this.isSelected) {
        this.$store.commit('chat/DELETE_FROM_SELECTED_MESSAGES', this.message, {
          root: true,
        });

        if (this.selectedMessages.length === 0)
          this.$store.commit(
            'ui/REMOVE_ACTION_IF_LAST',
            'add-selected-message',
            { root: true }
          );
      } else {
        if (this.selectedMessages.length === 0)
          this.$store.commit('ui/ADD_ACTION', 'add-selected-message', {
            root: true,
          });

        this.$store.commit('chat/ADD_SELECTED_MESSAGE', this.message, {
          root: true,
        });
      }
    },

    openMenu(e, message) {
      this.$emit('open-menu', e, message);
    },

    searchRepliedMessage(e, message) {
      this.$emit('search-replied', e, message);
    },

    formatDate(date = '') {
      let fullDate = new Date(date),
        d = fullDate.getDate().toString(),
        m = (fullDate.getMonth() + 1).toString(),
        y = fullDate.getFullYear().toString().slice(2);

      d = d.length === 1 ? `0${d}` : d;
      m = m.length === 1 ? `0${m}` : m;

      return `${d}.${m}.${y}`;
    },

    formatTime(date = '') {
      let fullDate = new Date(date),
        h = fullDate.getHours().toString(),
        m = fullDate.getMinutes().toString();
      h = h.length === 1 ? `0${h}` : h;
      m = m.length === 1 ? `0${m}` : m;

      return `${h}:${m}`;
    },
  },
};
</script>

<style scoped lang="scss">
.unselectable {
  user-select: none;
}

.viewer {
  cursor: pointer;
  background: #3d3d3d;

  &__image {
    cursor: pointer;
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3d3d3d;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
  }
}

.message {
  min-width: 200px;
  max-width: 500px;
  //width: 100%;
  padding: 0.5rem 1rem;
  //margin: 1rem;
  border-radius: 8px;

  &--red {
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.45) !important;
  }

  &--orange {
    box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.45) !important;
  }

  &--green {
    box-shadow: 0 0 0 2px rgba(0, 128, 0, 0.45) !important;
  }

  &--blue {
    box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.45) !important;
  }

  &__badge {
    position: absolute;
    right: -1rem;
    top: -0.75rem;
    z-index: 1;

    &.sender {
      right: unset;
      left: -1rem;
    }
  }

  &__contents {
    position: relative;
  }

  &__select-shield {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &__content {
    margin-bottom: 0.5rem;
    word-break: break-word;
    white-space: break-spaces;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ::v-deep &__link {
    text-decoration: none;
  }

  &__support,
  &__date,
  &__edited {
    font-size: 14px;
    text-align: right;
    color: #8e909bff;

    span {
      font-size: 12px;
    }
  }

  &.light {
    background: #f3f3f3;
  }

  &.dark {
  }

  &.sender {
    margin-left: auto;

    &.history {
      &.light {
        background: #7e7e7e;
      }

      &.dark {
        background: #606060;
      }

      .message__content {
        font-weight: 700;
      }
    }

    &.light {
      background: #dbffdf;

      .message__support,
      .message__date,
      .message_edited {
        color: #6cbe70;
      }

      ::v-deep .message__link {
        color: green;
      }
    }

    &.dark {
      background: #2196f3;

      .message__support,
      .message__date,
      .message_edited {
        color: #b9e0ff;
      }

      ::v-deep .message__link {
        color: black;
      }
    }
  }
}
</style>
