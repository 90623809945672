<template>
  <div class="grid-tile">
    <v-btn
      class="scroll-to-message-button"
      absolute
      icon
      color="orange"
      @click="scrollToMessage"
    >
      <v-icon>mdi-email-search</v-icon>
    </v-btn>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FileGrid',

  methods: {
    scrollToMessage(e) {
      e.preventDefault();
      this.$emit('scroll-to-message');
    },
  },
};
</script>

<style lang="scss">
.grid-tile {
  position: relative;
  height: 150px;
  width: 150px;

  .scroll-to-message-button {
    z-index: 1;
    top: 0;
    right: 0;
  }
}
</style>
