<template>
  <v-menu
    :close-on-click="true"
    :close-on-content-click="true"
    offset-y
    max-width="154"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon text small color="blue">
        <v-icon>mdi-play-speed</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="option in options"
        v-bind:key="option.label"
        :class="{ 'blue--text': playbackRate === option.value }"
        @click="$emit('playbackRateChange', option.value)"
      >
        <v-list-item-title>{{ option.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'PlaybackRateDropdown',
  data() {
    return {
      options: [
        { label: '1x', value: 1 },
        { label: '1.5x', value: 1.5 },
        { label: '2x', value: 2 },
      ],
    };
  },

  props: {
    playbackRate: {
      type: Number,
      default() {
        return 1;
      },
    },
  },
};
</script>
