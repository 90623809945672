var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Історія чатів "),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("(за добу)")])])]),(!_vm.loading)?_c('v-card-text',[_c('div',{staticClass:"mb-6 px-6"},[_c('v-text-field',{ref:"searchInput",attrs:{"hide-details":"","label":"Пошук по first/last/user name","prepend-icon":"mdi-magnify","clearable":""},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_c('div',[_c('v-virtual-scroll',{attrs:{"bench":20,"items":_vm.chats,"height":"400","item-height":"67.20"},scopedSlots:_vm._u([{key:"default",fn:function({ item, index }){return [_c('v-list-item',{key:item.chatId + index,on:{"click":function($event){return _vm.setChat(item.chatId)}}},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"mr-4",staticStyle:{"position":"relative"}},[(item.isVIP)?_c('span',{staticStyle:{"position":"absolute","top":"0","left":"50%","transform":"translate(-50%, -50%)","z-index":"1"}},[_vm._v("👑")]):_vm._e(),_c('v-avatar',{attrs:{"color":"teal","size":"42"}},[(item.avatar)?_c('img',{attrs:{"src":_vm.blobContainerUrl + item.avatar}}):_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.from.first_name.charAt(0).toUpperCase()))])])],1),_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"mb-2 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getName(item))+" ")]),_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.messages[item.messages.length - 1].supportId !== null ? 'Мен:' : `${item.from.first_name}:`)+" "+_vm._s(_vm.messageInfo(item.messages[item.messages.length - 1]))+" ")])]),_c('div',[(
                        _vm.isToday(
                          new Date(
                            item.messages[item.messages.length - 1].createdAt
                          )
                        )
                      )?_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.formatTime( Math.round( item.messages[item.messages.length - 1].createdAt ) ))+" ")]):_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.formatDate( Math.round( item.messages[item.messages.length - 1].createdAt ) ))+" ")]),_c('div',{staticClass:"text-center mt-2 mr-2"},[_c('v-badge',{attrs:{"color":item.unreadMessagesCount > 0
                            ? 'pink'
                            : 'transparent',"content":item.unreadMessagesCount}})],1)])])])],1)]}}],null,false,1631084044)})],1)]):_vm._e(),(!_vm.loading)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Закрити")]),_c('v-btn',{attrs:{"color":"blue","text":""},on:{"click":_vm.removeChatHistory}},[_vm._v("Очистити")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }