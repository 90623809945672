<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Вхід в Promin CRM</v-toolbar-title>
              </v-toolbar>

              <form @submit.prevent="loginUser" method="POST">
                <v-card-text class="px-8"> </v-card-text>

                <v-card-actions class="pb-6 px-8">
                  <v-spacer></v-spacer>
                  <v-btn type="submit" color="blue" class="white--text"
                    >Увійти</v-btn
                  >
                </v-card-actions>
              </form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import msal from '../lib/msalAuth';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  data() {
    return {};
  },

  methods: {
    async loginUser() {
      try {
        const msalInstance = await msal.createMsalInstance();
        const account = await this.getAccountOrSignIn(msalInstance);

        const response = await this.acquireToken(msalInstance, account);

        await this.loginAndRedirect(response);
      } catch (error) {
        console.error(error || 'Login went wrong');
      }
    },
    async getAccountOrSignIn(msalInstance) {
      const accounts = await msalInstance.getAllAccounts();
      return accounts.length > 0 ? accounts[0] : null;
    },
    async acquireToken(msalInstance, account) {
      const loginRequest = { scopes: [`${process.env.VUE_APP_SCOPE}`] };
      return account
        ? msalInstance.acquireTokenSilent({ account })
        : msalInstance.loginPopup({ ...loginRequest });
    },
    async loginAndRedirect(response) {
      try {
        await this.$store.dispatch('auth/login', {
          token: response.accessToken,
        });
        location.href = '/';
      } catch (error) {
        console.error(error || 'Failed to set credentials');
      }
    },
  },
};
</script>

<style></style>
