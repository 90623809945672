import Vue from 'vue';
import Vuex from 'vuex';

import ui from './modules/ui';
import auth from './modules/auth';
import chat from './modules/chat';
import support from './modules/support';
import notification from './modules/notification';
import messageTemplates from './modules/messageTemplates';
import audio from './modules/audio';
import feature from './modules/feature';

import adminChat from './modules/admin/chat';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    ui,
    auth,
    chat,
    support,
    notification,
    messageTemplates,
    audio,
    feature,
    adminChat,
  },
});

export default store;
