<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title class="mb-4">
        <span class="text-h5">Редагувати Шаблон</span>
      </v-card-title>

      <v-card-text>
        <v-row v-if="messageTemplate && !messageTemplate.supportId">
          <v-col>
            <v-text-field
              v-model="folder"
              label="Назва папки..."
              outlined
              dense
              hide-details
              class="mb-4"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              v-model="subFolder"
              label="Назва підпапки..."
              outlined
              dense
              hide-details
              class="mb-4"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model="title"
          label="Написати титулку шаблону..."
          outlined
          dense
          hide-details
          class="mb-4"
        />

        <v-textarea
          v-model="text"
          label="Написати шаблон..."
          auto-grow
          outlined
          dense
          hide-details
          rows="10"
          row-height="15"
        />

        <v-btn @click="openFileInput" color="blue mt-4" text elevation="0">
          <input
            @change="addFiles"
            ref="file-input"
            type="file"
            multiple
            style="width: 0"
          />
          Додати файли
        </v-btn>

        <ul style="list-style: none; padding: 0" class="file-list">
          <li v-for="(fileItem, index) in files" class="file-list__item">
            <div v-if="fileItem.thumbnail" style="position: relative">
              <div class="thumbnail">
                <div class="thumbnail__container">
                  <div
                    class="thumbnail__image"
                    :style="{
                      'background-image': `url(${fileItem.thumbnail})`,
                    }"
                  ></div>
                </div>
              </div>

              <div
                style="
                  position: absolute;
                  top: 0.25rem;
                  right: 0.25rem;
                  background: rgba(69, 90, 100, 0.6);
                "
                class="d-flex align-center rounded"
              >
                <v-file-input
                  @change="changeFile($event, index)"
                  prepend-icon="mdi-sync"
                  class="reload-btn mr-1 mt-0"
                  hide-input
                  dense
                  dark
                />

                <v-btn @click="removeFile(index)" small dark icon>
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>

            <div v-else>
              <div class="d-flex align-center justify-space-between py-2">
                <div :class="{ 'red--text': fileItem.status === 'error' }">
                  <v-icon
                    dense
                    :color="fileItem.status === 'error' ? 'red' : ''"
                    >mdi-file-outline</v-icon
                  >
                  <span
                    class="d-inline-block ml-1 text-truncate"
                    style="max-width: 240px; vertical-align: middle"
                    >{{ fileItem.file.name }}</span
                  >
                </div>

                <div class="rounded d-flex align-center">
                  <v-file-input
                    @change="changeFile($event, index)"
                    prepend-icon="mdi-sync"
                    class="reload-btn mr-1 mt-0"
                    hide-input
                    dense
                  />

                  <v-btn @click="removeFile(index)" small icon>
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close">Закрити</v-btn>
        <v-btn color="blue darken-1" :loading="loading" text @click="save"
          >Зберегти</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { BLOB_CONTAINER_URL } from '@/lib/const';

export default {
  name: 'MessageTemplatesEditDialog',
  data() {
    return {
      blobContainerUrl: BLOB_CONTAINER_URL,

      loading: false,
      templateId: '',
      folder: '',
      subFolder: '',
      supportId: '',
      text: '',
      title: '',
      files: [],
    };
  },

  watch: {
    show(val) {
      if (val) {
        this.$store.commit(
          'ui/ADD_ACTION',
          'open-message-templates-edit-dialog',
          { root: true }
        );
        this.templateId = this.messageTemplate._id;
        this.folder = this.messageTemplate.path.split('/')[0];
        this.subFolder = this.messageTemplate.path.split('/')[1];
        this.supportId = this.messageTemplate.supportId;
        this.text = this.messageTemplate.text;
        this.title = this.messageTemplate.title;
        this.files = this.initializeFiles(this.messageTemplate.files);
      } else {
        setTimeout(() => {
          this.$store.commit(
            'ui/REMOVE_ACTION_IF_LAST',
            'open-message-templates-edit-dialog',
            { root: true }
          );
        }, 200);
      }
    },
  },

  computed: {
    show: {
      get() {
        return (
          this.$store.state.ui.currentDialog.name ===
          'messageTemplatesEditDialog'
        );
      },

      set(show) {
        if (!show)
          this.$store.commit(
            'ui/SET_CURRENT_DIALOG',
            { name: 'messageTemplatesDialog' },
            { root: true }
          );
      },
    },

    messageTemplate() {
      return this.show
        ? this.$store.state.ui.currentDialog.data.messageTemplate
        : null;
    },
  },

  methods: {
    /** @param files - Array<{ name: string;  mimetype: string; pathToFile: string }> */
    initializeFiles(files) {
      if (!files) return [];

      return files.map(file => {
        const thumbnail =
          file.mimetype.indexOf('image') === 0
            ? `${this.blobContainerUrl}${file.folder}/${file.id}`
            : null;

        return {
          file,
          ...(thumbnail ? { thumbnail } : {}),
        };
      });
    },

    openFileInput() {
      this.$refs['file-input'].click();
    },

    save() {
      if (this.loading) return;
      this.loading = true;

      this.$store
        .dispatch(
          'messageTemplates/edit_message_template',
          {
            templateId: this.templateId,
            title: this.title,
            text: this.text,
            path: this.subFolder
              ? `${this.folder.trim()}/${this.subFolder.trim()}`
              : this.folder.trim(),
            supportId: this.supportId,
            files: this.files.map(item => item.file),
          },
          { root: true }
        )
        .then(res => {
          this.close();
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.show = false;
    },

    addFiles(e) {
      const files = Array.from(e.currentTarget.files);
      if (files.length === 0) return;

      const filesWithThumbnails = files.map(file => {
        const thumbnail =
          file.type.indexOf('image') === 0 ? URL.createObjectURL(file) : null;

        return {
          file,
          ...(thumbnail ? { thumbnail } : {}),
        };
      });

      this.files = [...this.files, ...filesWithThumbnails];
      console.log(this.files);
    },

    changeFile(file, index) {
      const thumbnail =
        file.type.indexOf('image') === 0 ? URL.createObjectURL(file) : null;

      file = {
        file,
        ...(thumbnail ? { thumbnail } : {}),
      };

      this.files.splice(index, 1, file);
      console.log(this.files);
    },

    removeFile(index) {
      this.files.splice(index, 1);
      console.log(this.files);
    },
  },
};
</script>

<style lang="scss">
.file-list {
  &__item {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.thumbnail {
  border-radius: 8px;
  overflow: hidden;

  &__container {
    cursor: pointer;
    background: #3d3d3d;
  }

  &__image {
    cursor: pointer;
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3d3d3d;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
  }
}

.reload-btn {
  .v-input__prepend-outer {
    margin: 0;
  }

  .v-icon {
    font-size: 1rem;
  }
}
</style>
