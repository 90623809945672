import { FEATURES } from '@/lib/const';

const { FILE_SECTION } = FEATURES;

const areArraysDifferent = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return true;
  }

  const difference = arr1.filter(
    el1 => !arr2.some(el2 => el1.name === el2.name)
  );

  return difference.length !== 0;
};

const state = {
  features: [{ name: FILE_SECTION, label: 'Файли', isEnabled: false }],
};

const getters = {
  isFeatureEnabled(state) {
    return name => state.features.find(f => f.name === name)?.isEnabled;
  },
};

const mutations = {
  INITIALIZE_STATE(state) {
    const localStorageFeatures = JSON.parse(
      window.localStorage.getItem('features')
    );

    if (localStorageFeatures) {
      state.features = localStorageFeatures;
    }
  },

  SYNC_LOCAL_STORAGE(state) {
    const localStorageFeatures = JSON.parse(
      window.localStorage.getItem('features')
    );

    if (!localStorageFeatures) {
      localStorage.setItem('features', JSON.stringify(state.features));
    } else {
      const shouldUpdateLocalStorage = areArraysDifferent(
        localStorageFeatures,
        state.features
      );

      if (shouldUpdateLocalStorage) {
        const newFeatures = state.features.map(feature => {
          const persistedFeature = localStorageFeatures.find(
            f => f.name === feature.name
          );
          return persistedFeature ? { ...persistedFeature } : { ...feature };
        });
        localStorage.setItem('features', JSON.stringify(newFeatures));
      }
    }
  },

  SET_IS_FEATURE_ENABLED(state, payload) {
    const { name, value } = payload;
    state.features = state.features.map(feature =>
      feature.name === name ? { ...feature, isEnabled: value } : feature
    );
    localStorage.setItem('features', JSON.stringify(state.features));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
