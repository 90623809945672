import {
  SET_SOURCE_AND_PLAY,
  SET_SOURCE,
  SET_IS_PLAYING,
  SET_CURRENT_TIME,
  SET_CURRENT_POSITION,
  SET_PLAYBACK_RATE,
} from '../types';

const state = {
  source: '',
  isPlaying: false,
  currentTime: 0,
  currentPosition: 0,
  playbackRate: 1,
};

const actions = {
  [SET_SOURCE_AND_PLAY]({ commit, state }, payload) {
    if (state.isPlaying) {
      commit(SET_IS_PLAYING, false);
    }
    commit(SET_SOURCE, payload);
    setTimeout(() => commit(SET_IS_PLAYING, true), 100);
  },
};

const mutations = {
  [SET_SOURCE](state, payload) {
    if (state.source !== payload) {
      state.source = payload;
    }
  },

  [SET_IS_PLAYING](state, payload) {
    if (state.isPlaying !== payload) {
      state.isPlaying = payload;
    }
  },

  [SET_CURRENT_TIME](state, payload) {
    state.currentTime = payload;
  },

  [SET_CURRENT_POSITION](state, payload) {
    state.currentPosition = payload;
  },

  [SET_PLAYBACK_RATE](state, payload) {
    state.playbackRate = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
