const axios = require('axios');

const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const routes = {
  get: {
    supports: `${origin}/${prefix}/supports`,
    support: `${origin}/${prefix}/supports/{id}`,
  },

  post: {
    chats: `${origin}/${prefix}/supports/{id}/chats`,
  },
};

exports.getRoutes = () => {
  return routes;
};

exports.getAll = (query = {}) => {
  let params = Object.keys(query).length
    ? `?${new URLSearchParams(query).toString()}`
    : '';

  const url = routes.get.supports + params;
  return axios.get(url);
};

exports.getOne = id => {
  const url = routes.get.support.replace('{id}', id);
  return axios.get(url);
};

exports.chats = (id, chatIds) => {
  const url = routes.post.chats.replace('{id}', id);
  let data = {
    chatIds,
  };

  return axios.post(url, data);
};
