<template>
  <v-dialog v-model="show" hide-overlay persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        Будь ласка, зачекайте!
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LoaderDialog',
  computed: {
    show() {
      return this.$store.state.ui.currentDialog.name === 'loaderDialog';
    },
  },
};
</script>

<style scoped lang="scss">
.v-dialog > .v-card > .v-card__text {
  padding-top: 0.75rem;
}
</style>
