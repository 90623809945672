<template>
  <v-dialog
    :value="isOpen"
    fullscreen
    no-click-animation
    persistent
    @keydown="handleKeyDown"
  >
    <v-card class="image-editor">
      <div :id="containerId" class="image-editor-container"></div>
    </v-card>
  </v-dialog>
</template>
<script>
import FilerobotImageEditor from 'filerobot-image-editor';
import { translations } from './translations';

const { TABS, TOOLS } = FilerobotImageEditor;

export default {
  name: 'ImageEditor',
  props: {
    containerId: String,
    isOpen: Boolean,
    image: Object,
    onClose: Function,
    onSave: Function,
  },

  data() {
    return {
      config: {
        onBeforeSave: () => false,
        onSave: this.handleSave,
        annotationsCommon: {
          fill: '#ff0000',
          stroke: '#ff0000',
        },
        Pen: {
          strokeWidth: 5,
        },
        theme: {
          palette: {
            'bg-primary': this.backgroundColor,
            'bg-secondary': this.backgroundColor,
          },
          typography: {
            fontFamily: 'Roboto, Arial',
          },
        },
        translations,
        Text: { text: 'Text...' },
        Rotate: { angle: 90, componentType: 'buttons' },
        tabsIds: [TABS.ADJUST, TABS.ANNOTATE],
        defaultTabId: TABS.ANNOTATE,
        defaultToolId: TOOLS.TEXT,
      },
      editor: null,
    };
  },

  watch: {
    isOpen() {
      if (this.isOpen) {
        this.$nextTick(() => {
          this.config.source = this.image.URL;
          this.editor = new FilerobotImageEditor(
            document.querySelector(`#${this.containerId}`),
            this.config
          );
          this.editor.render({
            onClose: this.handleClose,
          });
        });
      }
    },
  },

  methods: {
    handleClose() {
      this.editor.terminate();
      this.onClose();
    },
    handleSave(editedImageObject) {
      editedImageObject.imageCanvas.toBlob(blob => {
        const fileName = `image_${Date.now()}`;
        const file = new File([blob], fileName, {
          type: blob.type,
        });
        this.onSave(file, this.image.index);
      }, 'image/jpeg');
      this.handleClose();
    },
    handleKeyDown(event) {
      // escape
      if (event.keyCode === 27) {
        this.handleClose();
      }
    },
  },
};
</script>
<style lang="scss">
.image-editor,
.image-editor-container {
  height: 100%;
  width: 100%;
}

.image-editor.theme--dark {
  .FIE_root,
  .FIE_canvas-node,
  .FIE_tab {
    background-color: #1e1e1e !important;
  }

  .FIE_tab[aria-selected='true'] {
    background-color: #ecf3ff !important;
  }
}
</style>
