const axios = require('axios');

const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const originPath = `${origin}/${prefix}/message-templates`;
const routes = {
  get: {
    messageTemplates: originPath,
  },

  post: {
    messageTemplate: originPath,
  },

  put: {
    messageTemplate: `${originPath}/{templateId}`,
  },

  delete: {
    messageTemplate: `${originPath}/{templateId}`,
  },
};

exports.getRoutes = () => {
  return routes;
};

exports.getAll = () => {
  const url = routes.get.messageTemplates;
  return axios.get(url);
};

exports.create = ({ title, text, path, supportId, sentBy, files }) => {
  const url = routes.post.messageTemplate;
  const data = {
    title,
    text,
    path,
    sentBy,
    ...(supportId ? { supportId } : {}),
  };

  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));

  if (files?.length > 0) {
    files.forEach(file => formData.append('files[]', file));
  }

  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

exports.edit = ({
  templateId,
  title,
  text,
  path,
  supportId,
  sentBy,
  files,
}) => {
  const url = routes.put.messageTemplate.replace('{templateId}', templateId);
  let data = {
    title,
    text,
    path,
    ...(supportId ? { supportId } : {}),
    sentBy,
  };

  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));
  const savedFiles = files.filter(file => !(file instanceof File));
  formData.set('savedFiles', JSON.stringify(savedFiles));

  files
    .filter(file => file instanceof File)
    .forEach(newFile => formData.append('files[]', newFile));

  return axios.put(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

exports.delete = (templateId, sentBy) => {
  const url = routes.delete.messageTemplate.replace('{templateId}', templateId);
  let data = { templateId, sentBy };

  return axios.delete(url, { data });
};
