<template>
  <v-dialog v-model="show" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon dark v-bind="attrs" v-on="on"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </template>

    <v-card>
      <v-card-title class="mb-4">
        <span class="text-h5">Новий Шаблон</span>
      </v-card-title>

      <v-card-text>
        <v-radio-group v-model="common" row>
          <v-radio label="Спільний" :value="true"></v-radio>
          <v-radio label="Мій" :value="false"></v-radio>
        </v-radio-group>

        <v-row v-if="common">
          <v-col>
            <v-text-field
              v-model="folder"
              label="Назва папки..."
              outlined
              dense
              hide-details
              class="mb-4"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              v-model="subFolder"
              label="Назва підпапки..."
              outlined
              dense
              hide-details
              class="mb-4"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model="title"
          label="Написати титулку шаблону..."
          outlined
          dense
          hide-details
          class="mb-4"
        />

        <v-textarea
          v-model="text"
          label="Написати шаблон..."
          auto-grow
          outlined
          dense
          hide-details
          rows="10"
          row-height="15"
        />

        <v-btn @click="openFileInput" color="blue mt-4" text elevation="0">
          <input
            @change="addFiles"
            ref="file-input"
            type="file"
            multiple
            style="width: 0"
          />
          Додати файли
        </v-btn>

        <ul style="list-style: none; padding: 0" class="file-list">
          <li v-for="(fileItem, index) in files" class="file-list__item">
            <div v-if="fileItem.thumbnail" style="position: relative">
              <div class="thumbnail">
                <div class="thumbnail__container">
                  <div
                    class="thumbnail__image"
                    :style="{
                      'background-image': `url(${fileItem.thumbnail})`,
                    }"
                  ></div>
                </div>
              </div>

              <div
                style="
                  position: absolute;
                  top: 0.25rem;
                  right: 0.25rem;
                  background: rgba(69, 90, 100, 0.6);
                "
                class="d-flex align-center rounded"
              >
                <v-file-input
                  @change="changeFile($event, index)"
                  prepend-icon="mdi-sync"
                  class="reload-btn mr-1 mt-0"
                  hide-input
                  dense
                  dark
                />

                <v-btn @click="removeFile(index)" small dark icon>
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>

            <div v-else>
              <div class="d-flex align-center justify-space-between py-2">
                <div :class="{ 'red--text': fileItem.status === 'error' }">
                  <v-icon
                    dense
                    :color="fileItem.status === 'error' ? 'red' : ''"
                    >mdi-file-outline</v-icon
                  >
                  <span
                    class="d-inline-block ml-1 text-truncate"
                    style="max-width: 240px; vertical-align: middle"
                    >{{ fileItem.file.name }}</span
                  >
                </div>

                <div class="rounded d-flex align-center">
                  <v-file-input
                    @change="changeFile($event, index)"
                    prepend-icon="mdi-sync"
                    class="reload-btn mr-1 mt-0"
                    hide-input
                    dense
                  />

                  <v-btn @click="removeFile(index)" small icon>
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close">Закрити</v-btn>
        <v-btn color="blue darken-1" :loading="loading" text @click="save"
          >Зберегти</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MessageTemplatesCreateDialog',
  data() {
    return {
      loading: false,
      text: '',
      title: '',
      folder: '',
      subFolder: '',
      common: true,
      files: [],
    };
  },

  computed: {
    show: {
      get() {
        return (
          this.$store.state.ui.currentDialog.name ===
          'messageTemplatesCreateDialog'
        );
      },

      set(show) {
        if (show) {
          this.$store.commit(
            'ui/SET_CURRENT_DIALOG',
            { name: 'messageTemplatesCreateDialog' },
            { root: true }
          );
          this.$store.commit(
            'ui/ADD_ACTION',
            'open-message-templates-create-dialog',
            { root: true }
          );
        } else {
          this.$store.commit(
            'ui/SET_CURRENT_DIALOG',
            { name: 'messageTemplatesDialog' },
            { root: true }
          );
          setTimeout(() => {
            this.$store.commit(
              'ui/REMOVE_ACTION_IF_LAST',
              'open-message-templates-create-dialog',
              { root: true }
            );
          }, 200);
        }
      },
    },
  },

  methods: {
    openFileInput() {
      this.$refs['file-input'].click();
    },

    save() {
      if (this.loading) return;
      this.loading = true;

      this.$store
        .dispatch(
          'messageTemplates/create_message_template',
          {
            title: this.title,
            text: this.text,
            common: this.common,
            path: this.subFolder
              ? `${this.folder.trim()}/${this.subFolder.trim()}`
              : this.folder.trim(),
            files: this.files.map(item => item.file),
          },
          { root: true }
        )
        .then(messageTemplate => {
          this.close();
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.show = false;

      this.title = '';
      this.text = '';
      this.folder = '';
      this.subFolder = '';
      this.files = [];
      this.common = true;
    },

    addFiles(e) {
      const files = Array.from(e.currentTarget.files);
      if (files.length === 0) return;

      const filesWithThumbnails = files.map(file => {
        const thumbnail =
          file.type.indexOf('image') === 0 ? URL.createObjectURL(file) : null;

        return {
          file,
          ...(thumbnail ? { thumbnail } : {}),
        };
      });

      this.files = [...this.files, ...filesWithThumbnails];
    },

    changeFile(file, index) {
      const thumbnail =
        file.type.indexOf('image') === 0 ? URL.createObjectURL(file) : null;

      file = {
        file,
        ...(thumbnail ? { thumbnail } : {}),
      };

      this.files.splice(index, 1, file);
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.file-list {
  &__item {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.thumbnail {
  border-radius: 8px;
  overflow: hidden;

  &__container {
    cursor: pointer;
    background: #3d3d3d;
  }

  &__image {
    cursor: pointer;
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3d3d3d;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
  }
}

.reload-btn {
  .v-input__prepend-outer {
    margin: 0;
  }

  .v-icon {
    font-size: 1rem;
  }
}
</style>
