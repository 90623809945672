<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="chats"
      :loading="loading"
      loading-text="Завантажую чати"
      sort-by="lastMessageAt"
      class="elevation-1"
      :items-per-page="-1"
      hide-default-footer
      height="calc(100vh - 123px)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Статистика</v-toolbar-title>
          <v-spacer></v-spacer>
          <span>Чатів сьогодні {{ chats.length }}</span>
        </v-toolbar>
      </template>

      <template v-slot:item.from="{ item }">
        <v-avatar color="teal" size="32" class="mr-3">
          <img v-if="item.avatar" :src="blobContainerUrl + item.avatar" />
          <span v-else class="white--text">{{
            item.from.first_name.charAt(0).toUpperCase()
          }}</span>
        </v-avatar>
        <span>{{ getName(item) }}</span>
      </template>

      <template v-slot:item.lastMessageAt="{ item }">
        <span>{{ formatDate(item.lastMessageAt) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import chatsApi from '@/api/admin/chats';
import { formatDate, formatTime } from '@/lib/utils';
import { BLOB_CONTAINER_URL } from '@/lib/const';

export default {
  name: 'Statistics',
  data() {
    return {
      blobContainerUrl: BLOB_CONTAINER_URL,

      chats: [],
      loading: false,

      headers: [
        { text: "Ім'я/Нік", value: 'from' },
        {
          text: 'Останнє повідомлення',
          value: 'lastMessageAt',
        },
      ],
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    getName(chat) {
      let name = `${chat.from.first_name || ''} ${chat.from.last_name || ''}`;
      if (name.trim().length === 0) name = chat.from.username;

      return name.trim() || 'No name';
    },

    formatDate(date) {
      return formatDate(date) + ' | ' + formatTime(date);
    },

    initialize() {
      this.loading = true;

      (async () => {
        await this.getChatsByDate();
        this.loading = false;
      })();
    },

    async getChatsByDate() {
      try {
        const { data } = await chatsApi.getChatsByDate();
        this.chats = data;
      } catch (e) {
        console.error('Не зміг прогрузити chatsByDate =(', e);
      }
    },
  },
};
</script>

<style scoped></style>
