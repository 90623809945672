<template>
  <div class="d-flex justify-space-between align-content-center">
    <slot></slot>
    <v-btn
      class="align-self-center"
      icon
      color="orange"
      @click="scrollToMessage"
    >
      <v-icon>mdi-email-search</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'FileRow',

  methods: {
    scrollToMessage(e) {
      e.preventDefault();
      this.$emit('scroll-to-message');
    },
  },
};
</script>
