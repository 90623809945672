import Vue from 'vue';
import chatsApi from '@/api/chats';
import messagesApi from '@/api/messages';
import {
  getExistenceByKeyValue,
  getObjectByKeyValue,
  getObjectIndexByKeyValue,
} from '@/lib/utils';
import { SEARCH_TYPE } from '../types';

const state = {
  isBotBlocked: false,
  chats: [],
  currentChatId: 0,
  counts: {
    all: 0,
    new: 0,
    newVIP: 0,
    newUzb: 0,
    newParking: 0,
    survey: 0,
    finances: 0,
    assignId: 0,
    lead: 0,
    register: 0,
    call: 0,
    legalization: 0,
    legalizationDocuments: 0,
    sheriff: 0,
    leadFollowUp: 0,
  },

  folders: [
    {
      label: 'Всі',
      name: 'all',
      isDepartment: false,
      filter() {
        return state.chats;
      },
    },

    {
      label: 'Нові',
      name: 'new',
      isDepartment: false,
      filter() {
        return state.chats.filter(
          chat =>
            !chat.isVIP &&
            !chat.isRead &&
            !chat.departments.includes('uzb') &&
            !chat.departments.includes('parking')
        );
      },
    },

    {
      label: 'Нові VIP',
      name: 'newVIP',
      isDepartment: false,
      filter() {
        return state.chats.filter(chat => chat.isVIP && !chat.isRead);
      },
    },

    {
      label: 'Нові UZB',
      name: 'newUzb',
      icon: '🇺🇿',
      isDepartment: false,
      filter() {
        return state.chats.filter(
          chat => !chat.isRead && chat.departments.indexOf('uzb') >= 0
        );
      },
    },

    {
      label: 'Нові Автопарки',
      name: 'newParking',
      icon: 'mdi-parking',
      isDepartment: false,
      filter() {
        return state.chats.filter(
          chat => !chat.isRead && chat.departments.indexOf('parking') >= 0
        );
      },
    },

    {
      label: 'Договора',
      name: 'survey',
      icon: 'mdi-format-list-bulleted',
      isDepartment: true,
      filter() {
        return state.chats.filter(
          chat => chat.departments.indexOf('survey') >= 0
        );
      },
    },
    {
      label: 'Фінанси',
      name: 'finances',
      icon: 'mdi-finance',
      isDepartment: true,
      filter() {
        return state.chats.filter(
          chat => chat.departments.indexOf('finances') >= 0
        );
      },
    },
    {
      label: 'Приписати ключ',
      name: 'assignId',
      icon: 'mdi-key',
      isDepartment: true,
      filter() {
        return state.chats.filter(
          chat => chat.departments.indexOf('assignId') >= 0
        );
      },
    },
    {
      label: 'Кадри ЛГ',
      name: 'legalization',
      icon: 'mdi-passport',
      isDepartment: true,
      filter() {
        return state.chats.filter(
          chat => chat.departments.indexOf('legalization') >= 0
        );
      },
    },
    {
      label: 'Wypis/ID ЛГ',
      name: 'legalizationDocuments',
      icon: 'mdi-taxi',
      isDepartment: true,
      filter() {
        return state.chats.filter(
          chat => chat.departments.indexOf('legalizationDocuments') >= 0
        );
      },
    },
    {
      label: 'Реєстр',
      name: 'register',
      icon: 'mdi-account-plus',
      isDepartment: true,
      filter() {
        return state.chats.filter(
          chat => chat.departments.indexOf('register') >= 0
        );
      },
    },
    {
      label: 'Ліди',
      name: 'lead',
      icon: 'mdi-cash',
      isDepartment: true,
      filter() {
        return state.chats.filter(
          chat => chat.departments.indexOf('lead') >= 0
        );
      },
    },
    {
      label: 'Дзвінки',
      name: 'call',
      icon: 'mdi-phone',
      isDepartment: true,
      filter() {
        return state.chats.filter(
          chat => chat.departments.indexOf('call') >= 0
        );
      },
    },
    {
      label: 'Шериф',
      name: 'sheriff',
      icon: 'mdi-police-badge-outline',
      isDepartment: true,
      filter() {
        return state.chats.filter(
          chat => chat.departments.indexOf('sheriff') >= 0
        );
      },
    },
    {
      label: 'Чати',
      name: 'leadFollowUp',
      icon: 'mdi-chat-alert-outline',
      isDepartment: true,
      filter() {
        return state.chats.filter(
          chat => chat.departments.indexOf('leadFollowUp') >= 0
        );
      },
    },
  ],

  chatModes: {
    standard: '',
    editMessage: 'editMessage',
  },
  chatMode: '',
  replyToMessage: {},
  forwardFrom: 0,
  forwardTo: 0,
  forwardMessages: [],
  selectedMessages: [],
  currentMessage: {},
  scrollToMessageId: -1,
  messageInput: '',
  searchInput: '',
  searchType: SEARCH_TYPE.USER,
  searchDate: '',
  currentFolder: 'all',
  sequenceSort:
    JSON.parse(window.localStorage.getItem('sequenceSort')) || false,
  messageReplyWaitTime: { vip: 5, regular: 15 },
};

const getters = {
  isBotBlocked(state) {
    return state.isBotBlocked;
  },
  regularFolders(state) {
    return state.folders.filter(f => !f.isDepartment);
  },

  departmentFolders(state) {
    return state.folders.filter(f => f.isDepartment);
  },

  sortedChats(state) {
    let chats = [...state.chats];

    if (state.searchInput.length) {
      chats = chats.filter(f => {
        return (
          f.userIds?.some(id => {
            return (
              id
                .toLocaleLowerCase()
                .indexOf(state.searchInput.toLocaleLowerCase()) >= 0
            );
          }) ||
          (f.from.first_name &&
            f.from.first_name
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase()) >= 0) ||
          (f.from.last_name &&
            f.from.last_name
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase()) >= 0) ||
          (f.from.first_name &&
            f.from.last_name &&
            f.from.first_name
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase().split(' ')[0]) >=
              0 &&
            f.from.last_name
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase().split(' ')[1]) >=
              0) ||
          (f.from.username &&
            f.from.username
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase()) >= 0) ||
          (f.info &&
            f.info
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase()) >= 0) ||
          (f.additionalInfo &&
            f.additionalInfo
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase()) >= 0) ||
          (f.officeVisitsInfo &&
            f.officeVisitsInfo
              .toLocaleLowerCase()
              .indexOf(state.searchInput.toLocaleLowerCase()) >= 0)
        );
      });
    } else {
      chats = [
        ...state.folders
          .filter(f => state.currentFolder === f.name)[0]
          .filter(),
      ];
    }

    chats.sort((chatOne, chatTwo) => {
      let chatOneLastMessageTimestamp =
        chatOne.messages[chatOne.messages.length - 1]?.createdAt || 0;
      let chatTwoLastMessageTimestamp =
        chatTwo.messages[chatTwo.messages.length - 1]?.createdAt || 0;

      if (state.sequenceSort) {
        chatOneLastMessageTimestamp = chatOne.sortMessage?.createdAt || 0;
        chatTwoLastMessageTimestamp = chatTwo.sortMessage?.createdAt || 0;
      }

      return chatTwoLastMessageTimestamp - chatOneLastMessageTimestamp;
    });

    return chats;
  },

  currentChat(state) {
    return (
      state.chats.filter(chat => chat.chatId === state.currentChatId)[0] || {}
    );
  },

  isStandardMode(state) {
    return state.chatMode === '';
  },

  isMessageEditMode(state) {
    return state.chatMode === 'editMessage';
  },

  messages(state, getters) {
    return getters.currentChat.messages || [];
  },

  selectedMessagesIds(state) {
    return state.selectedMessages.map(x => x._id);
  },

  hasSelectedMessages(state) {
    return state.selectedMessages.length > 0;
  },
};

const mutations = {
  SET_CHATS(state, { chats }) {
    const chatIds = state.chats.map(chat => chat.chatId);
    chats.forEach(chat => {
      if (chatIds.indexOf(chat.chatId) < 0) state.chats.push(chat);
    });
  },

  SET_IS_BOT_BLOCKED(state, status) {
    state.isBotBlocked = status;
  },

  SET_CURRENT_CHAT_ID(state, chatId) {
    state.currentChatId = chatId;
  },

  ADD_CHAT(state, chat) {
    let hasChat = !!getObjectByKeyValue(state.chats, chat.chatId, 'chatId');
    if (hasChat) return;

    state.chats.push(chat);
  },

  UPDATE_CHAT(state, from) {
    let chat = getObjectByKeyValue(state.chats, from.id, 'chatId');
    chat.from = from;
  },

  // SET_CHAT_COUNT(state, { groupName, count }) {
  //     if (state.counts.hasOwnProperty(groupName))
  //         state.counts[groupName] = count;
  // },

  SET_CHAT_COUNTS(state, counts) {
    Object.keys(state.counts).forEach(countKey => {
      state.counts[countKey] = counts[countKey];
    });
  },

  SET_CHAT_SORT_MESSAGE(state, sortMessage) {
    let chat = getObjectByKeyValue(state.chats, sortMessage.chatId, 'chatId');
    if (chat) chat.sortMessage = sortMessage;
  },

  SET_UNREAD_MESSAGES_COUNT(state, { chatId, count }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.unreadMessagesCount = count;
  },

  ADD_CHAT_MESSAGE(state, message) {
    let chat = getObjectByKeyValue(state.chats, message.chatId, 'chatId');
    if (chat) {
      let messageIds = chat.messages.map(x => x.messageId);
      if (messageIds.indexOf(message.messageId) < 0) {
        chat.messages.push(message);
        chat.messages.sort(
          (messageOne, messageTwo) =>
            messageOne.createdAt - messageTwo.createdAt
        );
      }
    }
  },

  EDIT_MESSAGE(state, fields) {
    let chat = getObjectByKeyValue(state.chats, fields.chatId, 'chatId');

    if (chat) {
      let messageIndex = getObjectIndexByKeyValue(
        chat.messages,
        fields.messageId,
        'messageId'
      );

      if (messageIndex === null) return;

      const removeReplyToMessageField = field => {
        for (let i = chat.messages.length - 1; i >= 0; i--) {
          let message = chat.messages[i];
          let isEditedMessage =
            message.replyToMessage &&
            message.replyToMessage.messageId === fields.messageId;

          if (isEditedMessage) Vue.delete(message.replyToMessage, field);
        }
      };
      const mediaProperties = [
        'photo',
        'document',
        'videoNote',
        'video',
        'voice',
      ];
      const findMediaType = message => {
        let messageKeys = Object.keys(message);

        for (let i = 0; i < messageKeys.length; i++) {
          if (mediaProperties.indexOf(messageKeys[i]) >= 0)
            return messageKeys[i];
        }

        return null;
      };
      const handleTypeChange = (currentMessage, editedMessage) => {
        const currentMessageMediaType = findMediaType(currentMessage);
        const editedMessageMediaType = findMediaType(editedMessage);

        if (!currentMessageMediaType || !editedMessageMediaType) return;

        if (currentMessageMediaType !== editedMessageMediaType) {
          delete chat.messages[messageIndex][currentMessageMediaType];
          removeReplyToMessageField(currentMessageMediaType);
        }
      };

      if (findMediaType(chat.messages[messageIndex])) {
        if (
          chat.messages[messageIndex].hasOwnProperty('text') &&
          Object.keys(fields).indexOf('text') < 0
        ) {
          delete chat.messages[messageIndex]['text'];
          removeReplyToMessageField('text');
        }
      }

      handleTypeChange(chat.messages[messageIndex], fields);

      Object.keys(fields).forEach(field => {
        Vue.set(chat.messages[messageIndex], field, fields[field]);
      });

      const messageContentFields = [
        'text',
        'photo',
        'document',
        'videoNote',
        'video',
        'voice',
      ];

      //set changed fields for repliedMessage after message removal
      for (let i = chat.messages.length - 1; i >= 0; i--) {
        let message = chat.messages[i];
        let isEditedMessage =
          message.replyToMessage &&
          message.replyToMessage.messageId === fields.messageId;

        if (isEditedMessage) {
          const fieldsToChange = Object.keys(fields).filter(
            field => messageContentFields.indexOf(field) >= 0
          );

          fieldsToChange.forEach(field => {
            Vue.set(message.replyToMessage, field, fields[field]);
          });
        }
      }
    }
  },

  DELETE_MESSAGE(state, { chatId, messageId }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) {
      let messageIndex = getObjectIndexByKeyValue(
        chat.messages,
        messageId,
        'messageId'
      );
      if (messageIndex) chat.messages.splice(messageIndex, 1);

      if (chat.messages.length === 0) return;

      //set isRemoved for repliedMessage after message removal
      for (let i = chat.messages.length - 1; i >= 0; i--) {
        let message = chat.messages[i];
        let isRemovedMessage =
          message.replyToMessage &&
          message.replyToMessage.messageId === messageId;

        if (isRemovedMessage) message.replyToMessage.isRemoved = true;
      }
    }
  },

  SET_MESSAGE_COMMENT(state, { chatId, messageId, comment }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) {
      let messageIndex = getObjectIndexByKeyValue(
        chat.messages,
        messageId,
        'messageId'
      );
      if (messageIndex !== null)
        Vue.set(chat.messages[messageIndex], 'comment', comment);
    }
  },

  SET_MESSAGE_COLOR(state, { chatId, messageId, color }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) {
      let messageIndex = getObjectIndexByKeyValue(
        chat.messages,
        messageId,
        'messageId'
      );
      if (messageIndex !== null)
        Vue.set(chat.messages[messageIndex], 'color', color);
    }
  },

  SET_CHAT_MODE(state, mode = '') {
    if (Object.values(state.chatModes).indexOf(mode) >= 0)
      state.chatMode = mode;
  },

  SET_REPLY_TO_MESSAGE(state, message = {}) {
    if (Object.keys(message).length > 0) {
      let chat = getObjectByKeyValue(state.chats, message.chatId, 'chatId');
      message.from = chat.from;
    }

    state.replyToMessage = message;
  },

  ADD_SELECTED_MESSAGE(state, message) {
    let messageExists = getExistenceByKeyValue(
      state.selectedMessages,
      message._id,
      '_id'
    );
    if (!messageExists) state.selectedMessages.push(message);
  },

  DELETE_FROM_SELECTED_MESSAGES(state, message) {
    let messageIndex = getObjectIndexByKeyValue(
      state.selectedMessages,
      message._id,
      '_id'
    );
    if (messageIndex >= 0) state.selectedMessages.splice(messageIndex, 1);
  },

  SET_SELECTED_MESSAGES(state, messages = []) {
    state.selectedMessages = messages;
  },

  SET_CURRENT_MESSAGE(state, message = {}) {
    state.currentMessage = message;
  },

  SET_MESSAGE_INPUT(state, text) {
    state.messageInput = text;
  },

  SET_SEARCH_INPUT(state, text) {
    state.searchInput = text;
  },

  SET_SEQUENCE_SORT(state, sequenceSort) {
    window.localStorage.setItem('sequenceSort', JSON.stringify(sequenceSort));
    state.sequenceSort = sequenceSort;
  },

  SET_CURRENT_FOLDER(state, folderName) {
    state.currentFolder = folderName;
  },

  SET_FORWARD_FROM(state, chatId = 0) {
    state.forwardFrom = chatId;
  },

  SET_FORWARD_TO(state, chatId = 0) {
    state.forwardTo = chatId;
  },

  SET_FORWARD_MESSAGES(state, messages = []) {
    state.forwardMessages = messages;
  },

  SET_INFO(state, { chatId, info }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.info = info;
  },

  SET_ADDITIONAL_INFO(state, { chatId, additionalInfo }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.additionalInfo = additionalInfo;
  },

  SET_OFFICE_VISITS_INFO(state, { chatId, officeVisitsInfo }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.officeVisitsInfo = officeVisitsInfo;
  },

  SET_CREDENTIALS(state, { chatId, credentials }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.credentials = credentials;
  },

  SET_USER_IDS(state, { chatId, userIds }) {
    const chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.userIds = userIds;
  },

  SET_VIP_STATUS(state, { chatId, isVIP }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.isVIP = isVIP;
  },

  SET_READ_STATUS(state, { chatId, isRead }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.isRead = isRead;
  },

  SET_APPROVED_BY(state, { chatId, supportId }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.approvedBy = supportId;
  },

  SET_BLOCKED_BY(state, { chatId, supportId }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.blockedBy = supportId;
  },

  SET_DEPARTMENTS(state, { chatId, departments }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.departments = departments;
  },

  SET_CHAT_LANGUAGE(state, { chatId, language }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.language = language;
  },

  //not used
  SET_START_CHAT(state, { chatId }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.scenario = 'supportScenario';
  },

  //not used
  SET_END_CHAT(state, { chatId }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) chat.scenario = '';
  },

  SET_SCROLL_TO_MESSAGE_ID(state, { messageId }) {
    state.scrollToMessageId = messageId;
  },

  SET_SEARCH_TYPE(state, { searchType }) {
    state.searchType = searchType;
  },

  SET_SEARCH_DATE(state, { searchDate }) {
    state.searchDate = searchDate;
  },

  SET_MESSAGE_REPLY_WAIT_TIME(state, messageReplyWaitTime) {
    state.messageReplyWaitTime = messageReplyWaitTime;
  },
};

const actions = {
  //Api calls
  get_chats({ commit, state }, payload = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        let chatsResponse = await chatsApi.getAll({
          limit: payload.limit || 20,
          skip: payload.skip || 0,
        });

        let chats = chatsResponse.data;
        let chatsWithMessages = chats.filter(chat => chat.messages.length > 0);
        let chatsWithNoMessages = chats.filter(
          chat => chat.messages.length === 0
        );

        if (chatsWithNoMessages.length)
          console.log('chatsWithNoMessages', chatsWithNoMessages);

        commit('SET_CHATS', { chats: chatsWithMessages });
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },

  //not used
  get_chat({ commit, state }, chatId) {
    return new Promise(async (resolve, reject) => {
      try {
        let chatResponse = await chatsApi.getOne(chatId);
        let chat = chatResponse.data;
        if (chat.messages && chat.messages.length === 0)
          throw 'Chat has no messages';

        commit('ADD_CHAT', chat);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },

  get_more_chat_messages({ state }, { chatId, skip }) {
    return new Promise(async (resolve, reject) => {
      try {
        let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');

        let messagesResponse = await messagesApi.getAll(chatId, {
          limit: 50,
          skip: typeof skip === 'number' ? skip : chat.messages.length,
        });

        let messageIds = chat.messages.map(x => x.messageId);
        let newMessages = messagesResponse.data.filter(
          x => messageIds.indexOf(x.messageId) < 0
        );

        let allMessages = [...newMessages, ...chat.messages];
        chat.messages = allMessages.sort(
          (messageOne, messageTwo) =>
            messageOne.createdAt - messageTwo.createdAt
        );

        resolve(newMessages);
      } catch (e) {
        reject(e);
      }
    });
  },

  get_chat_counts({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        let counts = await chatsApi.getCounts();
        commit('SET_CHAT_COUNTS', counts.data);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },

  // get_chat_unread_messages_count({ state, commit }, chatId) {
  //     return messagesApi.getUnreadCount(chatId);
  // },

  set_message_comment({ commit, dispatch }, payload) {
    return messagesApi.comment(
      payload.messageId,
      payload.chatId,
      payload.comment
    );
  },

  edit_message({ commit }, payload) {
    return messagesApi.edit(payload.messageId, payload.chatId, payload.text);
  },

  delete_message({ commit }, payload) {
    return messagesApi.delete(payload.messageId, payload.chatId);
  },

  set_color({ commit }, payload) {
    return messagesApi.color(payload.messageId, payload.chatId, payload.color);
  },

  set_info({ commit }, payload) {
    return chatsApi.info(payload.chatId, payload.info, payload.supportId);
  },

  set_additional_info({ commit }, payload) {
    return chatsApi.additionalInfo(
      payload.chatId,
      payload.additionalInfo,
      payload.supportId
    );
  },

  set_office_visits_info({ commit }, payload) {
    return chatsApi.officeVisitsInfo(
      payload.chatId,
      payload.officeVisitsInfo,
      payload.supportId
    );
  },

  set_credentials({ commit }, payload) {
    return chatsApi.credentials(
      payload.chatId,
      payload.credentials,
      payload.supportId
    );
  },

  set_user_ids({ commit }, payload) {
    const { chatId, userIds } = payload;

    return chatsApi.setUserIds(chatId, userIds);
  },

  set_approved_by({ commit, dispatch }, payload) {
    return chatsApi.approve(payload.chatId, payload.supportId);
  },

  set_blocked_by({ commit, dispatch }, payload) {
    return chatsApi.block(payload.chatId, payload.supportId);
  },

  set_vip_status({ commit, dispatch }, payload) {
    return chatsApi.vip(payload.chatId, payload.isVIP);
  },

  set_read_status({ commit, dispatch }, payload) {
    return chatsApi.read(payload.chatId, payload.supportId, payload.isRead);
  },

  set_departments({ commit, dispatch }, payload) {
    return chatsApi.departments(payload.chatId, payload.departments);
  },

  set_start_chat({ commit, dispatch }, payload) {
    return chatsApi.startChat(payload.chatId);
  },

  set_end_chat({ commit, dispatch }, payload) {
    return chatsApi.endChat(payload.chatId);
  },

  get_message_reply_wait_time({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const messageReplyWaitTime =
          await messagesApi.getMessageReplyWaitTime();
        commit('SET_MESSAGE_REPLY_WAIT_TIME', messageReplyWaitTime.data);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
