<template>
  <v-menu bottom left>
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex">
        <search-message-date-picker v-if="searchType === SEARCH_TYPE.CHAT" />
        <v-btn dark icon v-bind="attrs" v-on="on" class="mr-2">
          <v-icon color="blue">{{ getMenuIcon(searchType) }}</v-icon>
        </v-btn>
      </div>
    </template>

    <v-list>
      <v-list-item
        v-for="item in items"
        v-bind:key="item.type"
        :disabled="isSearchTypeOptionDisabled(item.type)"
        @click="setSearchType(item.type)"
        link
      >
        <v-list-item-icon class="mr-4">
          <v-icon :color="searchType === item.type ? 'blue' : ''">{{
            item.icon
          }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            :class="{ 'blue--text': searchType === item.type }"
            >{{ item.text }}</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { SEARCH_TYPE } from '@/store/types';
import SearchMessageDatePicker from './SearchMessageDatePicker.vue';

export default {
  name: 'SearchTypeDropdown',

  components: {
    SearchMessageDatePicker,
  },

  props: {
    currentChatId: Number,
    searchType: String,
  },

  data() {
    return {
      SEARCH_TYPE,
      items: [
        {
          icon: 'mdi-account',
          text: 'В користувачах',
          type: SEARCH_TYPE.USER,
        },
        {
          icon: 'mdi-message-text',
          text: 'В повідомленнях',
          type: SEARCH_TYPE.MESSAGE,
        },
        {
          icon: 'mdi-comment-account',
          text: 'В активному чаті',
          type: SEARCH_TYPE.CHAT,
        },
      ],
    };
  },

  watch: {
    currentChatId(chatId) {
      if (chatId === 0) {
        this.setSearchType(SEARCH_TYPE.USER);
      }
    },
  },

  methods: {
    getMenuIcon(searchType) {
      switch (searchType) {
        case SEARCH_TYPE.MESSAGE:
          return 'mdi-message-text';
        case SEARCH_TYPE.CHAT:
          return 'mdi-comment-account';
        case SEARCH_TYPE.USER:
        default:
          return 'mdi-account';
      }
    },

    isSearchTypeOptionDisabled(searchType) {
      return searchType === SEARCH_TYPE.CHAT && this.currentChatId === 0;
    },

    setSearchType(searchType) {
      this.$store.commit('chat/SET_SEARCH_INPUT', '');
      this.$store.commit('chat/SET_SEARCH_TYPE', { searchType });
    },
  },
};
</script>
