const axios = require('axios');

const headers = {
  headers: {
    Authorization: window.localStorage.getItem('token'),
  },
};
const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const originPath = `${origin}/${prefix}/admins/bulk-messages`;
const routes = {
  get: {
    bulkMessages: originPath,
  },

  post: {
    bulkMessage: originPath,
    cancel: `${originPath}/{bulkMessageId}/cancel`,
    resendBulkMessageItem: `${originPath}/{bulkMessageId}/resend-item`,
  },

  put: {
    bulkMessage: `${originPath}/{bulkMessageId}`,
  },

  delete: {
    bulkMessage: `${originPath}/{bulkMessageId}`,
  },
};

exports.getRoutes = () => {
  return routes;
};

exports.getAll = () => {
  const url = routes.get.bulkMessages;
  return axios.get(url, headers);
};

exports.create = (bulkMessageData, text) => {
  const url = routes.post.bulkMessage;
  return axios.post(url, bulkMessageData, headers);
};

exports.cancel = bulkMessageId => {
  const url = routes.post.cancel.replace('{bulkMessageId}', bulkMessageId);
  return axios.post(url, {}, headers);
};

exports.resendBulkMessageItem = (bulkMessageId, bulkMessageItemId) => {
  const url = routes.post.resendBulkMessageItem.replace(
    '{bulkMessageId}',
    bulkMessageId
  );
  let data = { bulkMessageItemId };

  return axios.post(url, data, headers);
};

exports.edit = (bulkMessageId, bulkMessageData) => {
  const url = routes.put.bulkMessage.replace('{bulkMessageId}', bulkMessageId);
  return axios.put(url, bulkMessageData, headers);
};

exports.delete = bulkMessageId => {
  const url = routes.delete.bulkMessage.replace(
    '{bulkMessageId}',
    bulkMessageId
  );
  return axios.delete(url, headers);
};
