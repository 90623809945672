class ChatHistoryCollection {
  constructor() {
    this.dbName = 'prominLocal';
    this.collectionName = 'chatHistory';
    this.version = 1;

    this.db = null;
  }

  init() {
    return new Promise((resolve, reject) => {
      try {
        const DBOpenRequest = window.indexedDB.open(this.dbName, this.version);

        DBOpenRequest.onerror = e => {
          reject(e);
        };

        DBOpenRequest.onsuccess = e => {
          this.db = DBOpenRequest.result;
          resolve();
        };

        DBOpenRequest.onupgradeneeded = e => {
          this.db = e.target.result;

          this.db.onerror = event => {
            console.error(e, 'Error loading database');
          };

          this.db.createObjectStore(this.collectionName, { keyPath: 'chatId' });
        };
      } catch (e) {
        reject(e);
      }
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      try {
        const tx = this.db.transaction(this.collectionName, 'readonly');
        tx.onerror = e => {
          reject(e.target.error);
        };

        const store = tx.objectStore(this.collectionName);
        const getRequest = store.getAll();

        getRequest.onsuccess = e => {
          resolve(e.target.result);
        };

        getRequest.onerror = e => {
          reject(e.target.error);
        };
      } catch (e) {
        reject(e);
      }
    });
  }

  put(chatHistoryItem) {
    return new Promise((resolve, reject) => {
      try {
        const tx = this.db.transaction(this.collectionName, 'readwrite');
        tx.onerror = e => {
          reject(e.target.error);
        };

        const store = tx.objectStore(this.collectionName);
        const putRequest = store.put(chatHistoryItem);

        putRequest.onsuccess = e => {
          resolve(e.target.result);
        };

        putRequest.onerror = e => {
          reject(e.target.error);
        };
      } catch (e) {
        reject(e);
      }
    });
  }

  clear() {
    return new Promise((resolve, reject) => {
      try {
        const tx = this.db.transaction(this.collectionName, 'readwrite');
        tx.onerror = e => {
          reject(e.target.error);
        };

        const store = tx.objectStore(this.collectionName);
        const clearRequest = store.clear();

        clearRequest.onsuccess = e => {
          resolve();
        };

        clearRequest.onerror = e => {
          reject(e.target.error);
        };
      } catch (e) {
        reject(e);
      }
    });
  }

  remove(chatId) {
    return new Promise((resolve, reject) => {
      try {
        const tx = this.db.transaction(this.collectionName, 'readwrite');
        tx.onerror = e => {
          reject(e.target.error);
        };

        const store = tx.objectStore(this.collectionName);
        const deleteRequest = store.delete(chatId);

        deleteRequest.onsuccess = e => {
          resolve(e.target.result);
        };

        deleteRequest.onerror = e => {
          reject(e.target.error);
        };
      } catch (e) {
        reject(e);
      }
    });
  }
}

const chatHistoryCollection = new ChatHistoryCollection();
export default chatHistoryCollection;
