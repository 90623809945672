import messageTemplatesApi from '@/api/messageTemplates';
import { getObjectByKeyValue, getObjectIndexByKeyValue } from '@/lib/utils';

const state = {
  staticFolders: [
    {
      title: 'Мої',
      subFolders: [],
    },
    {
      title: 'Спільні',
      subFolders: [],
    },
  ],
  staticSubFolder: 'Корінь',
  messageTemplates: [],
  currentTabIndex: 0,
  currentSubFoldersTabIndex: 0,
};

const getters = {
  messageTemplates(state, getters, rootState, rootGetters) {
    if (state.currentTabIndex === 0) {
      return state.messageTemplates.filter(
        x =>
          x.supportId &&
          x.path === '' &&
          x.supportId === rootGetters['support/currentSupport']._id
      );
    } else if (state.currentTabIndex === 1) {
      return state.messageTemplates.filter(x => !x.supportId && x.path === '');
    } else if (state.currentTabIndex > 0 && state.currentTabIndex > 1) {
      let subFolders = getters.tabs[state.currentTabIndex]
        ? getters.tabs[state.currentTabIndex].subFolders
        : [];

      if (subFolders.length) {
        let folder = getters.tabs[state.currentTabIndex].title;
        let subFolder = subFolders[state.currentSubFoldersTabIndex];

        if (subFolder === state.staticSubFolder) {
          return state.messageTemplates.filter(
            x => !x.supportId && x.path === folder && x.path.indexOf('/') < 0
          );
        } else {
          return state.messageTemplates.filter(
            x => !x.supportId && x.path === `${folder}/${subFolder}`
          );
        }
      }

      return getters.tabs.length > 2
        ? state.messageTemplates.filter(
            x =>
              !x.supportId &&
              x.path === getters.tabs[state.currentTabIndex].title
          )
        : [];
    }

    return [];
  },

  tabs(state) {
    let folderTabs = [];
    let folderMap = new Map();

    state.messageTemplates.forEach(x => {
      if (x.hasOwnProperty('path') && x.path) {
        let hasSubFolder = x.path.indexOf('/') > 0;
        let folder = hasSubFolder ? x.path.split('/')[0] : x.path;
        let subFolder = hasSubFolder ? x.path.split('/')[1] : '';

        if (folderMap.has(folder)) {
          folderMap
            .get(folder)
            .add(hasSubFolder ? subFolder : state.staticSubFolder);
        } else {
          folderMap.set(
            folder,
            new Set().add(hasSubFolder ? subFolder : state.staticSubFolder)
          );
        }
      }
    });

    folderMap.forEach((value, key) => {
      const constructSubFolders = () => {
        if (
          value.size === 0 ||
          (value.size === 1 && value.has(state.staticSubFolder))
        )
          return [];

        let subFolders = Array.from(value);
        let staticSubFolderIndex = subFolders.indexOf(state.staticSubFolder);

        if (staticSubFolderIndex > 0) {
          subFolders.splice(staticSubFolderIndex, 1);
          subFolders.unshift(state.staticSubFolder);
        }

        return subFolders;
      };

      folderTabs.push({
        title: key,
        subFolders: constructSubFolders(),
      });
    });

    return [...state.staticFolders, ...folderTabs];
  },
};

const mutations = {
  SET_MESSAGE_TEMPLATES(state, messageTemplates) {
    state.messageTemplates = messageTemplates;
  },

  ADD_MESSAGE_TEMPLATE(state, messageTemplate) {
    state.messageTemplates.push(messageTemplate);
  },

  EDIT_MESSAGE_TEMPLATE(state, fields) {
    let messageTemplate = getObjectByKeyValue(
      state.messageTemplates,
      fields._id || fields.templateId,
      '_id'
    );
    const messageTemplateEditableFields = ['title', 'text', 'path', 'files'];

    Object.keys(fields).forEach(field => {
      if (messageTemplateEditableFields.indexOf(field) >= 0)
        messageTemplate[field] = fields[field];
    });
  },

  DELETE_MESSAGE_TEMPLATE(state, { templateId }) {
    let messageTemplateIndex = getObjectIndexByKeyValue(
      state.messageTemplates,
      templateId,
      '_id'
    );
    state.messageTemplates.splice(messageTemplateIndex, 1);
  },

  SET_CURRENT_TAB(state, tab) {
    state.currentTabIndex = tab;
  },

  SET_CURRENT_SUB_FOLDERS_TAB(state, tab) {
    state.currentSubFoldersTabIndex = tab;
  },
};

const actions = {
  //Api calls
  get_message_templates({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        let messagesResponse = await messageTemplatesApi.getAll();

        commit('SET_MESSAGE_TEMPLATES', messagesResponse.data);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },

  create_message_template({ state, rootGetters, commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const supportId = payload.common
          ? null
          : rootGetters['support/currentSupport']._id;
        const path = payload.common ? payload.path : '';

        const messageTemplateResponse = await messageTemplatesApi.create({
          title: payload.title,
          text: payload.text,
          path,
          supportId,
          sentBy: rootGetters['support/currentSupport']._id,
          files: payload.files,
        });

        commit('ADD_MESSAGE_TEMPLATE', messageTemplateResponse.data);

        let messageTemplate = getObjectByKeyValue(
          state.messageTemplates,
          messageTemplateResponse.data._id,
          '_id'
        );

        resolve(messageTemplate);
      } catch (e) {
        reject(e);
      }
    });
  },

  async edit_message_template({ rootGetters }, messageTemplate) {
    return await messageTemplatesApi.edit({
      ...messageTemplate,
      sentBy: rootGetters['support/currentSupport']._id,
    });
  },

  delete_message_template({ state, getters, commit, rootGetters }, templateId) {
    return messageTemplatesApi
      .delete(templateId, rootGetters['support/currentSupport']._id)
      .then(res => {
        if (getters.messageTemplates.length === 1) {
          state.currentTabIndex = 0;
          state.currentSubFoldersTabIndex = 0;
        }

        commit('DELETE_MESSAGE_TEMPLATE', { templateId });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
