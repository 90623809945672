<template>
  <div class="file-section">
    <div class="file-section-buttons d-flex flex-wrap align-center">
      <v-btn class="ma-1" @click="() => openDialog(MEDIA_TYPE.PHOTO)">{{
        translations[MEDIA_TYPE.PHOTO]
      }}</v-btn>
      <v-btn class="ma-1" @click="() => openDialog(MEDIA_TYPE.VIDEO)">{{
        translations[MEDIA_TYPE.VIDEO]
      }}</v-btn>
      <v-btn class="ma-1" @click="() => openDialog(MEDIA_TYPE.DOCUMENT)">{{
        translations[MEDIA_TYPE.DOCUMENT]
      }}</v-btn>
      <v-btn class="ma-1" @click="() => openDialog(MEDIA_TYPE.VOICE)">{{
        translations[MEDIA_TYPE.VOICE]
      }}</v-btn>
    </div>

    <v-dialog
      content-class="file-section-dialog"
      :value="isDialogOpen"
      @click:outside="closeDialog"
      width="660px"
    >
      <v-card class="content pl-3" height="60vh" width="660px">
        <div class="header text-h6">{{ translations[currentMediaType] }}</div>
        <div
          class="no-results d-flex justify-center align-center text-h4"
          v-if="items.length === 0"
        >
          {{ translations[currentMediaType] }} не знайдено
        </div>
        <file-grid
          v-else-if="currentMediaType === MEDIA_TYPE.PHOTO"
          @scroll-to-message="handleScrollToMessage"
          :loadMore="fetchData"
          :isLoading="isLoading"
          :items="items"
        />

        <video-gallery
          v-else-if="currentMediaType === MEDIA_TYPE.VIDEO"
          @scroll-to-message="handleScrollToMessage"
          :loadMore="fetchData"
          :isLoading="isLoading"
          :items="items"
        >
        </video-gallery>

        <file-list
          v-else-if="
            currentMediaType === MEDIA_TYPE.DOCUMENT ||
            currentMediaType === MEDIA_TYPE.VOICE
          "
          @scroll-to-message="handleScrollToMessage"
          :loadMore="fetchData"
          :isLoading="isLoading"
          :items="items"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import FileGrid from './FileGrid.vue';
import FileList from './FileList.vue';
import VideoGallery from './VideoGallery.vue';
import chatsApi from '@/api/chats';
import { mapMediaMessagesToUnifiedMedia } from '@/lib/mediaUtils';
import { getMonthFromDate, getYearFromDate, isSameMonth } from '@/lib/utils';

const PAGINATION_LIMIT = 20;
const MEDIA_TYPE = {
  PHOTO: 'photo',
  VIDEO: 'video',
  VIDEO_NOTE: 'videoNote',
  DOCUMENT: 'document',
  VOICE: 'voice',
};
const translations = {
  [MEDIA_TYPE.PHOTO]: 'Фото',
  [MEDIA_TYPE.VIDEO]: 'Відео',
  [MEDIA_TYPE.VIDEO_NOTE]: 'Відео Повідомлення',
  [MEDIA_TYPE.DOCUMENT]: 'Файли',
  [MEDIA_TYPE.VOICE]: 'Голосові Повідомлення',
};

export default {
  name: 'FileSection',
  components: {
    FileGrid,
    FileList,
    VideoGallery,
  },
  data() {
    return {
      isDialogOpen: false,
      currentMediaType: 'photo',
      items: [],
      isLoading: false,
      isAllFetched: false,

      gridSize: 4,

      MEDIA_TYPE,
      translations,
    };
  },

  watch: {
    currentMediaType() {
      this.items = [];
    },
  },

  computed: {
    currentChatId() {
      return this.$store.state.chat.currentChatId;
    },

    messages() {
      return this.$store.getters['chat/messages'];
    },
  },

  methods: {
    openDialog(type) {
      this.currentMediaType = type;
      this.isDialogOpen = true;
    },

    closeDialog() {
      this.isDialogOpen = false;
      this.items = [];
      this.isAllFetched = false;
    },

    async fetchData() {
      if (!this.isLoading && !this.isAllFetched) {
        this.isLoading = true;
        const skip = this.items.filter(item => item.src).length;
        const newItems = await this.getItems(skip);
        this.items = [...newItems];
        this.isLoading = false;
      }
    },

    async getItems(skip) {
      const mediaItems = await chatsApi.getMedia(
        this.currentChatId,
        this.currentMediaType,
        {
          limit: PAGINATION_LIMIT,
          skip,
        }
      );
      this.isAllFetched = mediaItems.length !== PAGINATION_LIMIT;
      return this.getItemsWithMonthSeparators(mediaItems);
    },

    getItemsWithMonthSeparators(items) {
      switch (this.currentMediaType) {
        case this.MEDIA_TYPE.PHOTO:
        case this.MEDIA_TYPE.VIDEO:
          return this.getGridItemsWithMonthSeparators(items);
        case this.MEDIA_TYPE.VOICE:
        case this.MEDIA_TYPE.DOCUMENT:
          return this.getListItemsWithMonthSeparators(items);
        default:
          return [];
      }
    },

    getGridItemsWithMonthSeparators(newItems) {
      let result = [...this.items];

      newItems.forEach(item => {
        if (result.length === 0) {
          this.getMonthGridRow(item, this.gridSize).forEach(item =>
            result.push(item)
          );
        } else if (
          !isSameMonth(item.createdAt, result[result.length - 1].createdAt)
        ) {
          if (result.length % 4 !== 0) {
            this.getPlaceholdersToFillGridRow(
              result.length,
              this.gridSize
            ).forEach(item => result.push(item));
          }
          this.getMonthGridRow(item, this.gridSize).forEach(item =>
            result.push(item)
          );
        }
        result.push(item);
      });

      return result;
    },

    getMonthGridRow(item, gridWidth) {
      const { createdAt, messageId } = item;
      return [
        {
          id: uuidv4(),
          month: getMonthFromDate(createdAt),
          year: getYearFromDate(createdAt),
          messageId,
        },
        ...Array(gridWidth - 1).fill(this.getPlaceholder()),
      ];
    },

    getPlaceholdersToFillGridRow(gridItemCount, gridWidth) {
      return Array(gridWidth - (gridItemCount % gridWidth)).fill(
        this.getPlaceholder()
      );
    },

    getPlaceholder() {
      return { id: uuidv4(), placeholder: true };
    },

    getMediaFromLoadedMessages() {
      const mediaMessages = this.messages
        .filter(message => message[this.currentMediaType])
        .sort((a, b) => b.createdAt - a.createdAt);

      return mapMediaMessagesToUnifiedMedia(
        mediaMessages,
        this.currentMediaType
      );
    },

    getListItemsWithMonthSeparators(newItems) {
      let result = [...this.items];

      newItems.forEach(item => {
        if (
          result.length === 0 ||
          !isSameMonth(item.createdAt, result[result.length - 1].createdAt)
        ) {
          result.push({
            id: uuidv4(),
            month: getMonthFromDate(item.createdAt),
            year: getYearFromDate(item.createdAt),
            messageId: item.messageId,
          });
        }
        result.push(item);
      });

      return result;
    },

    handleScrollToMessage(messageId) {
      this.$store.commit('chat/SET_SCROLL_TO_MESSAGE_ID', { messageId });
      this.closeDialog();
    },
  },

  updated() {
    if (this.isDialogOpen && this.items.length === 0 && !this.isAllFetched) {
      const mediaItems = this.getMediaFromLoadedMessages();
      if (mediaItems.length > 0) {
        this.items = [...this.getItemsWithMonthSeparators(mediaItems)];
      } else {
        this.fetchData();
      }
    }
  },
};
</script>

<style lang="scss">
.file-section {
  &-dialog {
    overflow: hidden;

    .content {
      .header {
        background-color: #1e1e1e;
        border-radius: 0 !important;
        margin-right: 10px;
        position: relative;
        z-index: 5;
      }

      &.theme--light {
        .header {
          background-color: #fff;
        }
      }

      .no-results {
        height: 100%;
      }

      .scroll-container {
        height: calc(100% - 30px);

        &.grid {
          height: calc(100% + 50px);
          margin-top: -80px;
        }
      }
    }
  }
}
</style>
