<template>
  <v-dialog v-model="show" max-width="400px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Виберіть одержувача...</span>
      </v-card-title>

      <v-container style="height: 544px" v-if="loading">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Пересилаю повідомлення...
          </v-col>

          <v-col cols="6">
            <v-progress-linear
              color="blue"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>

      <v-card-text v-if="!loading">
        <div class="mb-6 px-6">
          <v-text-field
            v-model="searchInput"
            ref="searchInput"
            hide-details
            label="Пошук по first/last/user name"
            prepend-icon="mdi-magnify"
            clearable
          ></v-text-field>
        </div>

        <div>
          <v-virtual-scroll
            :bench="20"
            :items="chats"
            height="400"
            item-height="67.20"
          >
            <template v-slot:default="{ item, index }">
              <v-list-item
                @click="setForwardData(item.chatId)"
                :key="item.chatId + index"
              >
                <v-list-item-content>
                  <div class="d-flex justify-space-between">
                    <div style="position: relative" class="mr-4">
                      <span
                        v-if="item.isVIP"
                        style="
                          position: absolute;
                          top: 0;
                          left: 50%;
                          transform: translate(-50%, -50%);
                          z-index: 1;
                        "
                        >👑</span
                      >

                      <v-avatar color="teal" size="42">
                        <img
                          v-if="item.avatar"
                          :src="blobContainerUrl + item.avatar"
                        />
                        <span v-else class="white--text">{{
                          item.from.first_name.charAt(0).toUpperCase()
                        }}</span>
                      </v-avatar>
                    </div>

                    <div class="flex-grow-1">
                      <div class="mb-2 font-weight-medium">
                        {{ getName(item) }}
                      </div>
                      <div class="text-truncate" style="max-width: 200px">
                        {{
                          item.messages[item.messages.length - 1].supportId !==
                          null
                            ? 'Мен:'
                            : `${item.from.first_name}:`
                        }}
                        {{
                          messageInfo(item.messages[item.messages.length - 1])
                        }}
                      </div>
                    </div>

                    <div>
                      <div
                        v-if="
                          isToday(
                            new Date(
                              item.messages[item.messages.length - 1].createdAt
                            )
                          )
                        "
                        class="text-body-2"
                      >
                        {{
                          formatTime(
                            Math.round(
                              item.messages[item.messages.length - 1].createdAt
                            )
                          )
                        }}
                      </div>
                      <div v-else class="text-body-2">
                        {{
                          formatDate(
                            Math.round(
                              item.messages[item.messages.length - 1].createdAt
                            )
                          )
                        }}
                      </div>
                      <div class="text-center mt-2 mr-2">
                        <v-badge
                          :color="
                            item.unreadMessagesCount > 0
                              ? 'pink'
                              : 'transparent'
                          "
                          :content="item.unreadMessagesCount"
                        ></v-badge>
                      </div>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </div>
      </v-card-text>

      <v-card-actions v-if="!loading">
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close">Скасувати</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDate, formatTime } from '@/lib/utils';
import { BLOB_CONTAINER_URL } from '@/lib/const';

export default {
  name: 'ForwardMessageDialog',
  data() {
    return {
      blobContainerUrl: BLOB_CONTAINER_URL,
      loading: false,
      searchInput: null,
    };
  },

  watch: {
    show(val) {
      if (val) {
        this.$store.commit('ui/ADD_ACTION', 'open-forward-message-dialog', {
          root: true,
        });
        setTimeout(() => this.$refs['searchInput'].focus(), 100);
      } else {
        setTimeout(() => {
          this.searchInput = null;
          this.$store.commit(
            'ui/REMOVE_ACTION_IF_LAST',
            'open-forward-message-dialog',
            { root: true }
          );
        }, 200);
      }
    },
  },

  computed: {
    show: {
      get() {
        return (
          this.$store.state.ui.currentDialog.name === 'forwardMessageDialog'
        );
      },

      set(show) {
        if (!show)
          this.$store.commit('ui/SET_CURRENT_DIALOG', {}, { root: true });
      },
    },

    message() {
      return this.show ? this.$store.state.ui.currentDialog.data.message : null;
    },

    chats() {
      let chats = this.$store.state.chat.chats;

      if (this.searchInput && this.searchInput.length) {
        chats = chats.filter(f => {
          return (
            (f.from.first_name &&
              f.from.first_name
                .toLocaleLowerCase()
                .indexOf(this.searchInput.toLocaleLowerCase()) >= 0) ||
            (f.from.last_name &&
              f.from.last_name
                .toLocaleLowerCase()
                .indexOf(this.searchInput.toLocaleLowerCase()) >= 0) ||
            (f.from.username &&
              f.from.username
                .toLocaleLowerCase()
                .indexOf(this.searchInput.toLocaleLowerCase()) >= 0) ||
            (f.info &&
              f.info
                .toLocaleLowerCase()
                .indexOf(this.searchInput.toLocaleLowerCase()) >= 0) ||
            (f.additionalInfo &&
              f.additionalInfo
                .toLocaleLowerCase()
                .indexOf(this.searchInput.toLocaleLowerCase()) >= 0)
          );
        });
      }

      chats.sort((chatOne, chatTwo) => {
        let chatOneLastMessageTimestamp =
          chatOne.messages[chatOne.messages.length - 1].createdAt;
        let chatTwoLastMessageTimestamp =
          chatTwo.messages[chatTwo.messages.length - 1].createdAt;

        return chatTwoLastMessageTimestamp - chatOneLastMessageTimestamp;
      });

      return chats;
    },

    selectedMessages() {
      return this.$store.state.chat.selectedMessages;
    },

    hasSelectedMessages() {
      return this.$store.getters['chat/hasSelectedMessages'];
    },

    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },

    currentSupport() {
      return this.$store.getters['support/currentSupport'];
    },
  },

  methods: {
    formatDate,
    formatTime,
    messageInfo(message) {
      let text = message.text;

      switch (true) {
        case message.hasOwnProperty('photo'):
          text = 'Фото';
          break;

        case message.hasOwnProperty('document'):
          text = 'Документ';
          break;

        case message.hasOwnProperty('voice'):
          text = 'Голосове повідомлення';
          break;

        case message.hasOwnProperty('videoNote'):
          text = 'Відео повідомлення';
          break;

        case message.hasOwnProperty('video'):
          text = 'Відео';
          break;
      }

      return text;
    },

    getName(chat) {
      let name = `${chat.from.first_name || ''} ${chat.from.last_name || ''}`;
      if (name.trim().length === 0) name = chat.from.username;

      return name.trim() || 'No name';
    },

    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    },

    setForwardData(toChat) {
      this.$store.commit('chat/SET_FORWARD_FROM', this.message.chatId, {
        root: true,
      });
      this.$store.commit('chat/SET_FORWARD_TO', toChat, { root: true });

      let messagesToForward = this.hasSelectedMessages
        ? this.selectedMessages
        : [this.message];
      this.$store.commit('chat/SET_FORWARD_MESSAGES', messagesToForward, {
        root: true,
      });

      if (this.hasSelectedMessages)
        this.$store.commit('chat/SET_SELECTED_MESSAGES', [], { root: true });

      //TODO: CHAT CHANGING SHOULD BE IN OTHER MODULE
      this.setChat(toChat);

      this.close();
    },

    async setChat(id) {
      if (this.currentChat.chatId === id) return;

      this.$store.commit('chat/SET_CURRENT_CHAT_ID', id, { root: true });
      this.$store.commit('ui/ADD_ACTION', 'open-chat', { root: true });

      this.$store.dispatch(
        'support/set_active_chats',
        {
          supportId: this.$store.state.auth.user._id,
          activeChats: [id],
        },
        { root: true }
      );

      await this.$store.dispatch(
        'support/add_chat_to_history',
        { chatId: id },
        { root: true }
      );

      if (this.currentChat.messages.length <= 50)
        this.$store.dispatch(
          'chat/get_more_chat_messages',
          { chatId: id },
          { root: true }
        );
    },

    close() {
      this.show = false;
      document.getElementById('messageInput').focus();
    },
  },
};
</script>

<style scoped lang="scss">
.v-dialog > .v-card > .v-card__text {
  padding-left: 0;
  padding-right: 0;
}

.v-list-item {
  padding: 0 1.5rem;
}
</style>
