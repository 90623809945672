<template>
  <v-list-group prepend-icon="mdi-table-cog" class="feature-group">
    <template v-slot:activator>
      <v-list-item-title>Фічери</v-list-item-title>
    </template>

    <v-list-item
      v-for="feature in features"
      class="toggle-container"
      v-bind:key="feature.name"
    >
      <v-list-item-action>
        <v-switch
          :input-value="feature.isEnabled"
          @change="value => setIsFeatureEnabled(feature.name, value)"
          inset
          color="blue"
        ></v-switch>
      </v-list-item-action>
      <v-list-item-title>{{ feature.label }}</v-list-item-title>
    </v-list-item>
  </v-list-group>
</template>
<script>
export default {
  name: 'FeaturesMenu',
  computed: {
    features() {
      return this.$store.state.feature.features;
    },
  },
  methods: {
    setIsFeatureEnabled(name, value) {
      this.$store.commit('feature/SET_IS_FEATURE_ENABLED', { name, value });
    },
  },
};
</script>
<style lang="scss">
.feature-group {
  .toggle-container {
    margin-left: 20px;
  }
}
</style>
