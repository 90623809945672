<template>
  <div class="audio-bar d-flex align-center pa-2">
    <div v-if="!isPlaying" class="mr-2">
      <v-btn icon text small color="blue" @click="onPlayAudio">
        <v-icon>mdi-play</v-icon>
      </v-btn>
    </div>
    <div v-else class="mr-2">
      <v-btn icon text small color="blue" @click="onPauseAudio">
        <v-icon>mdi-pause</v-icon>
      </v-btn>
    </div>
    <v-slider
      class="my-0"
      color="blue"
      @start="onStartSeek"
      @end="onStopSeek"
      :value="progress"
      :disabled="isDisabled"
      :messages="getTimer()"
    />
    <playback-rate-dropdown
      v-if="showPlaybackRateDropdown"
      :playbackRate="playbackRate"
      @playbackRateChange="
        playbackRate => $emit('playbackRateChange', playbackRate)
      "
    />
    <div v-if="onClose">
      <v-btn @click="onClose" icon text small>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import PlaybackRateDropdown from './PlaybackRateDropdown.vue';

export default {
  name: 'AudioBar',
  components: {
    PlaybackRateDropdown,
  },
  data() {
    return {
      shouldPlayAudioAfterStopSeek: false,
    };
  },

  computed: {
    progress() {
      return (this.currentTime / this.duration) * 100;
    },
  },

  props: {
    isPlaying: {
      type: Boolean,
      required: true,
    },
    onPlayAudio: {
      type: Function,
      required: true,
    },
    onPauseAudio: {
      type: Function,
      required: true,
    },
    onSetCurrentPosition: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
    },
    isDisabled: {
      type: Boolean,
    },
    currentTime: {
      type: Number,
    },
    duration: {
      type: Number,
    },
    showPlaybackRateDropdown: {
      type: Boolean,
      default() {
        return false;
      },
    },
    playbackRate: {
      type: Number,
    },
  },

  methods: {
    onStartSeek(position) {
      this.shouldPlayAudioAfterStopSeek = this.isPlaying;
      if (this.isPlaying) {
        this.onPauseAudio();
      }
      this.onSetCurrentPosition(position);
    },

    onStopSeek(position) {
      if (this.shouldPlayAudioAfterStopSeek) {
        this.onPlayAudio();
      }
      this.onSetCurrentPosition(position);
    },

    getTimer() {
      const time =
        this.isPlaying || !this.isDisabled ? this.currentTime : this.duration;
      const minutes = Math.floor(+time / 60);
      const seconds = Math.floor(+time % 60);
      return `${this.prependZeroIfLessThan10(
        minutes
      )} : ${this.prependZeroIfLessThan10(seconds)}`;
    },

    prependZeroIfLessThan10(value) {
      if (value < 10) {
        return `0${value}`;
      }
      return value;
    },
  },
};
</script>

<style lang="scss">
.audio-bar {
  min-width: 250px;

  .v-input__slot {
    margin-bottom: 0;
  }

  .v-messages {
    margin-left: 2px;
  }
}
</style>
