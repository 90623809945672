<template>
  <v-dialog :value="show" persistent width="360">
    <v-card color="primary" dark class="pt-2">
      <v-card-text>
        {{ message }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="outlined" block @click="$emit('hide')">{{
          cancelButtonText
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'GenericLoaderDialog',

  props: {
    cancelButtonText: String,
    message: String,
    show: Boolean,
  },
};
</script>
