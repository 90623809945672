const axios = require('axios');

const headers = {
  headers: {
    Authorization: window.localStorage.getItem('token'),
  },
};
const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const originPath = `${origin}/${prefix}/admins/chats`;
const routes = {
  get: {
    chats: originPath,
    chatsByDate: `${originPath}ByDate`,
  },
};

exports.getRoutes = () => {
  return routes;
};

exports.getAll = (query = {}) => {
  let params = Object.keys(query).length
    ? `?${new URLSearchParams(query).toString()}`
    : '';

  const url = routes.get.chats + params;
  return axios.get(url, headers);
};

exports.getChatsByDate = (date = new Date()) => {
  const isoDate = date.toISOString().slice(0, 10);

  const url = `${routes.get.chatsByDate}?date=${isoDate}`;
  return axios.get(url, headers);
};
