<template>
  <audio-bar
    :isPlaying="isMessageAudioPlaying"
    :onPlayAudio="playAudio"
    :onPauseAudio="pauseAudio"
    :onSetCurrentPosition="setCurrentPosition"
    :currentTime="audioCurrentTime"
    :duration="duration"
    :isDisabled="!isMessageAudioSelectedForPlayback"
  ></audio-bar>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import AudioBar from '@/components/common/bars/audioBar/AudioBar';
import {
  SET_SOURCE_AND_PLAY,
  SET_IS_PLAYING,
  SET_CURRENT_POSITION,
} from '@/store/types';

export default {
  name: 'VoiceMessage',
  components: {
    AudioBar,
  },

  data() {
    return {
      player: new Audio(),
    };
  },

  props: {
    source: {
      type: String,
      required: true,
    },
    duration: Number,
  },

  computed: {
    audioCurrentTime() {
      return this.isMessageAudioSelectedForPlayback
        ? this.$store.state.audio.currentTime
        : 0;
    },

    audioSource() {
      return this.$store.state.audio.source;
    },

    isAudioPlaying() {
      return this.$store.state.audio.isPlaying;
    },

    isMessageAudioSelectedForPlayback() {
      return this.audioSource === this.source;
    },

    isMessageAudioPlaying() {
      return this.isMessageAudioSelectedForPlayback
        ? this.isAudioPlaying
        : false;
    },
  },

  methods: {
    ...mapActions('audio', {
      setSourceAndPlay: SET_SOURCE_AND_PLAY,
    }),

    ...mapMutations('audio', {
      setIsPlaying: SET_IS_PLAYING,
      setCurrentPosition: SET_CURRENT_POSITION,
    }),

    playAudio() {
      if (this.isMessageAudioSelectedForPlayback) {
        this.setIsPlaying(true);
      } else {
        this.setSourceAndPlay(this.source);
      }
    },

    pauseAudio() {
      this.setIsPlaying(false);
    },
  },

  mounted() {
    if (!this.player.src) {
      this.player.src = this.source;
    }
  },
};
</script>

<style lang="scss" scoped></style>
