<template>
  <div class="mb-4 d-flex flex-column">
    <div class="d-flex align-center">
      <span>
        <span class="subtitle-1 font-weight-medium">ID: </span>
        <span class="font-weight-medium grey--text text--darken-1">{{
          getUserIds()
        }}</span>
      </span>
      <v-btn fab x-small color="primary" class="ml-4" @click="$emit('edit')">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserIdsSection',

  methods: {
    getUserIds() {
      const userIds = this.$store.getters['chat/currentChat'].userIds || [];
      return userIds.length ? userIds.join(', ') : 'ID не приписаний';
    },
  },
};
</script>
