/* eslint-disable no-async-promise-executor */
import Vue from 'vue';
import authApi from '@/api/auth';

const state = {
  token: window.localStorage.getItem('token') || '',
  user: JSON.parse(window.localStorage.getItem('user')) || {},
  isAdmin: JSON.parse(window.localStorage.getItem('isAdmin')) || false,
};

const getters = {
  token(state) {
    return state.token;
  },

  user(state) {
    return state.user;
  },

  isAuthorized(state) {
    return state.token.length > 0 && Object.keys(state.user).length > 0;
  },

  isAdmin(state) {
    return state.isAdmin;
  },
};

const mutations = {
  SET_CREDENTIALS(state, { token, user, isAdmin = false }) {
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('user', JSON.stringify(user));
    window.localStorage.setItem('isAdmin', JSON.stringify(isAdmin));

    state.token = token;
    state.user = user;
    state.isAdmin = isAdmin;
  },

  MUTATE_USER(state, { propName, propValue }) {
    Vue.set(state.user, propName, propValue);
    window.localStorage.setItem('user', JSON.stringify(state.user));
  },

  GET_TOKEN(state, { task }) {
    window.localStorage.getItem('token');
  },

  REMOVE_CREDENTIALS(state) {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('isAdmin');

    state.token = '';
    state.user = {};
    state.isAdmin = false;
  },
};

const actions = {
  mutate_user({ commit }, { propName, propValue }) {
    commit('MUTATE_USER', { propName, propValue });
  },

  login({ commit }, { token }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: user } = await authApi.login({ token });
        commit('SET_CREDENTIALS', {
          token,
          user: user.data,
          isAdmin: user.isAdmin,
        });
      } catch (error) {
        console.error(error);
        reject();
      }

      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
