<template>
  <v-menu v-model="show" :close-on-content-click="false" min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark icon v-bind="attrs" v-on="on">
        <v-icon color="blue">mdi-calendar</v-icon>
      </v-btn>
    </template>
    <v-date-picker
      v-model="date"
      @input="setSearchDate"
      locale="ru-RU"
      :min="minDate"
      :max="maxDate"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { getISODate } from '@/lib/utils';

export default {
  name: 'SearchMessageDatePicker',

  data() {
    return {
      show: false,
      date: getISODate(new Date()),
      maxDate: getISODate(new Date()),
    };
  },

  computed: {
    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },

    minDate() {
      return getISODate(this.currentChat.createdAt);
    },
  },

  methods: {
    setSearchDate(searchDate) {
      this.show = false;
      this.$store.commit('chat/SET_SEARCH_DATE', { searchDate });
    },
  },
};
</script>
