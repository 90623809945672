<template>
  <RecycleScroller
    class="scroll-container grid"
    @scroll-end="loadMore"
    :items="items"
    :item-size="160"
    :item-secondary-size="160"
    :grid-items="4"
  >
    <template v-slot="{ item }">
      <v-skeleton-loader
        v-if="isLoading"
        height="150"
        width="150"
        max-height="150"
        max-width="150"
        type="image"
      />
      <grid-tile
        v-else-if="item.src"
        @scroll-to-message="() => scrollToMessage(item.messageId)"
      >
        <img
          :src="item.src"
          @click="() => viewImage(item.src)"
          height="150"
          width="150"
        />
      </grid-tile>
      <div
        v-else-if="item.placeholder"
        style="height: 150px; width: 150px"
      ></div>
      <div
        class="grid-month-tile text-h5"
        v-else-if="item.month"
        style="height: 150px; width: 150px"
      >
        {{ item.month }}<br />{{ item.year }}
      </div>
    </template>
  </RecycleScroller>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller_1_1_2';
import { api as viewerApi } from 'v-viewer';
import GridTile from './GridTile.vue';

export default {
  name: 'FileGrid',

  components: {
    RecycleScroller,
    GridTile,
  },

  props: {
    items: Array,
    isLoading: Boolean,
    loadMore: Function,
  },

  methods: {
    viewImage(src) {
      const images = this.items.filter(item => item.src);
      const selectedImageIndex = images.findIndex(image => image.src === src);

      viewerApi({
        options: {
          toolbar: true,
          url: 'src',
          initialViewIndex: selectedImageIndex || 0,
        },
        images,
      });
    },

    scrollToMessage(messageId) {
      this.$emit('scroll-to-message', messageId);
    },
  },
};
</script>

<style lang="scss">
.scroll-container.grid {
  img {
    cursor: pointer;
    object-fit: cover;
  }

  .grid-month-tile {
    padding-top: 90px;
  }
}
</style>
