<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="label"
        :value="value"
        append-icon="mdi-clock"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-time-picker
      format="24hr"
      :active-picker="activePicker"
      :value="value"
      @change="handleChange"
      @update:active-picker="value => (this.activePicker = value)"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'TimePicker',

  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
  },

  data() {
    return {
      open: false,
      activePicker: 'HOUR',
    };
  },

  watch: {
    open(value) {
      if (!value) {
        this.activePicker = 'HOUR';
      }
    },
  },

  methods: {
    handleChange(value) {
      if (this.activePicker !== 'HOUR') {
        this.open = false;
      }
      this.$emit('input', value);
    },
  },
};
</script>
