import axios from 'axios';

const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const routes = {
  get: {
    customerVisits: `${origin}/${prefix}/customer-visits/{chatId}`,
  },
  post: {
    customerVisits: `${origin}/${prefix}/customer-visits/{chatId}`,
  },
  put: {
    customerVisits: `${origin}/${prefix}/customer-visits/{chatId}`,
  },
  delete: {
    customerVisits: `${origin}/${prefix}/customer-visits/{chatId}`,
  },
};

const getAll = ({ chatId }) => {
  const url = routes.put.customerVisits.replace('{chatId}', chatId);

  return axios.get(url);
};

const add = ({ chatId, customerVisit }) => {
  const url = routes.put.customerVisits.replace('{chatId}', chatId);
  const data = {
    customerVisit,
  };

  return axios.post(url, data);
};

const edit = ({ chatId, customerVisitWithId }) => {
  const url = routes.put.customerVisits.replace('{chatId}', chatId);
  const { id, admin, date, description, time } = customerVisitWithId;
  const data = {
    customerVisitWithId: { id, admin, date, description, time },
  };

  return axios.put(url, data);
};

const remove = ({ chatId, customerVisitId }) => {
  const url = routes.put.customerVisits.replace('{chatId}', chatId);
  const data = {
    customerVisitId,
  };

  return axios.delete(url, { data });
};

export default { getAll, add, edit, remove };
