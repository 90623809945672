<template>
  <div class="my-2 d-flex flex-column">
    <div class="body-1">{{ title }}</div>
    <v-radio-group
      @change="value => $emit('change', value)"
      :disabled="disabled"
      :value="value"
      class="mt-0"
      row
      dense
      hide-details
    >
      <v-radio
        v-for="option in options"
        :key="option.label"
        :label="option.label"
        :value="option.value"
      />
    </v-radio-group>
    <v-divider />
  </div>
</template>

<script>
export default {
  name: 'RadioFilter',

  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Boolean],
      required: false,
    },
  },
};
</script>
