// audio
// actions
export const SET_SOURCE_AND_PLAY = 'SET_SOURCE_AND_PLAY';

// mutations
export const SET_SOURCE = 'SET_SOURCE';
export const SET_IS_PLAYING = 'SET_IS_PLAYING';
export const SET_CURRENT_TIME = 'SET_CURRENT_TIME';
export const SET_CURRENT_POSITION = 'SET_CURRENT_POSITION';
export const SET_PLAYBACK_RATE = 'SET_PLAYBACK_RATE';

// chat
export const SEARCH_TYPE = {
  USER: 'user',
  MESSAGE: 'message',
  CHAT: 'chat',
};
