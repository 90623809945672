<template>
  <v-dialog :value="show" persistent max-width="600px">
    <v-card>
      <v-card-title class="text-h5 text-no-wrap">
        Ви впевнені, що хочете видалити цей впис?
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$emit('closeDelete')">Відмінити</v-btn>
        <v-btn color="primary" dark @click="$emit('deleteItemConfirm')">
          OK
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteDialog',

  props: {
    show: {
      type: Boolean,
      require: true,
    },
  },
};
</script>
