<template>
  <v-dialog :value="show" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="form.valid">
          <template v-for="column in columns">
            <div v-if="column.field" v-bind:key="column.value">
              <v-text-field
                v-if="column.field.type === 'textField'"
                v-model="form.item[column.value]"
                :label="column.text"
                :rules="column?.field.rules"
              />
              <v-textarea
                v-else-if="column.field.type === 'textArea'"
                v-model="form.item[column.value]"
                :label="column.text"
                :rules="column.field.rules"
              />
              <date-picker
                v-else-if="column.field.type === 'datePicker'"
                v-model="form.item[column.value]"
                :label="column.text"
                :max-date="column.field.maxDate"
                :rules="column.field.rules"
              />
              <time-picker
                v-else-if="column.field.type === 'timePicker'"
                v-model="form.item[column.value]"
                :label="column.text"
                :rules="column.field.rules"
              />
            </div>
          </template>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Закрити</v-btn>
        <v-btn color="primary" dark @click="handleSave">Зберегти</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from '../../datePicker/DatePicker.vue';
import TimePicker from '../../timePicker/TimePicker.vue';

export default {
  name: 'ItemDialog',
  components: {
    DatePicker,
    TimePicker,
  },

  props: {
    addItemButtonText: {
      type: String,
      default() {
        return 'Новий впис';
      },
    },
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      form: {
        valid: false,
        item: {
          ...this.item,
        },
      },
    };
  },

  watch: {
    item(value) {
      this.form.item = { ...value };
    },
    show(value) {
      if (value) {
        this.resetFormValidation();
      }
    },
  },

  methods: {
    validateForm() {
      return this.$refs.form.validate();
    },

    resetFormValidation() {
      if (this.$refs.form) {
        return this.$refs.form.resetValidation();
      }
    },

    handleSave() {
      const isFormValid = this.validateForm();

      if (!isFormValid) {
        return;
      }

      this.$emit('change', this.form.item);
      this.$emit('save');
    },
  },
};
</script>
