<template>
  <v-app-bar app flat height="72">
    <div
      class="d-flex align-center justify-space-between px-2"
      style="width: 100%"
    >
      <template v-if="hasSelectedMessages && !loading">
        <div class="d-flex align-center">
          <v-btn
            @click="forwardMessage"
            class="mr-2"
            color="blue"
            elevation="0"
            dark
            >Переслати ({{ selectedMessages.length }})</v-btn
          >
          <v-btn @click="deleteMessages" color="blue" dark elevation="0"
            >Видалити ({{ selectedMessages.length }})</v-btn
          >
        </div>
        <v-spacer></v-spacer>
        <v-btn @click="clearSelected" color="blue" text>Скасувати</v-btn>
      </template>

      <template v-else>
        <div class="supports">
          <div v-for="support in supportsInChat" class="pa-1 flex-shrink-0">
            <v-chip
              :color="
                currentSupport.nickname === support.nickname ? 'purple' : 'blue'
              "
              dark
              label
              small
            >
              <v-icon class="mr-1" color="white" small>mdi-account</v-icon>
              <span style="white-space: break-spaces; word-break: break-all">
                {{
                  currentSupport.nickname === support.nickname
                    ? 'Я'
                    : support.nickname
                }}
              </span>
            </v-chip>
          </div>
          <div class="d-flex flex-column ml-4">
            <span>vip: {{ messageReplyWaitTime?.vip }}</span>
            <span>regular: {{ messageReplyWaitTime?.regular }}</span>
          </div>
        </div>

        <audio-bar
          v-if="audioSource"
          :isPlaying="isAudioPlaying"
          :onPlayAudio="playAudio"
          :onPauseAudio="pauseAudio"
          :onSetCurrentPosition="setCurrentPosition"
          :onClose="closeAudioPlayer"
          :currentTime="audioCurrentTime"
          :duration="this.player.duration"
          showPlaybackRateDropdown
          :playbackRate="this.playbackRate"
          @playbackRateChange="onPlaybackRateChange"
        ></audio-bar>

        <v-btn
          v-if="Object.keys(currentChat).length"
          @click="toggleSidebar"
          icon
        >
          <v-icon>mdi-page-layout-sidebar-right</v-icon>
        </v-btn>
      </template>
    </div>
  </v-app-bar>
</template>

<script>
import { mapMutations } from 'vuex';
import AudioBar from '@/components/common/bars/audioBar/AudioBar';
import {
  SET_IS_PLAYING,
  SET_CURRENT_TIME,
  SET_CURRENT_POSITION,
  SET_SOURCE,
  SET_PLAYBACK_RATE,
} from '@/store/types';

export default {
  name: 'ActionsBar',
  components: {
    AudioBar,
  },

  data() {
    return {
      loading: false,
      debounce: 500,
      timeout: null,
      delete: false,
      player: new Audio(),
    };
  },

  computed: {
    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },

    supports() {
      return this.$store.state.support.supports;
    },

    currentSupport() {
      return this.$store.getters['support/currentSupport'];
    },

    supportsInChat() {
      return this.supports.filter(
        support => support.activeChats.indexOf(this.currentChat.chatId) >= 0
      );
    },

    selectedMessages() {
      return this.$store.state.chat.selectedMessages;
    },

    hasSelectedMessages() {
      return this.$store.getters['chat/hasSelectedMessages'];
    },

    showInfoBar: {
      get() {
        return this.$store.state.ui.showInfoBar;
      },

      set(show) {
        this.$store.commit('ui/SET_SHOW_INFO_BAR', show, { root: true });
      },
    },

    audioSource() {
      return this.$store.state.audio.source;
    },

    audioCurrentTime() {
      return this.$store.state.audio.currentTime;
    },

    isAudioPlaying() {
      return this.$store.state.audio.isPlaying;
    },

    currentAudioPosition() {
      return this.$store.state.audio.currentPosition;
    },

    playbackRate() {
      return this.$store.state.audio.playbackRate;
    },

    messageReplyWaitTime() {
      return this.$store.state.chat.messageReplyWaitTime;
    },
  },

  watch: {
    isAudioPlaying() {
      if (this.isAudioPlaying) {
        this.player.play();
      } else {
        this.player.pause();
      }
    },

    audioSource() {
      if (this.audioSource) {
        this.player.src = this.audioSource;
        this.player.playbackRate = this.playbackRate;
      }
    },

    currentAudioPosition() {
      if (this.audioSource) {
        this.player.currentTime =
          (this.currentAudioPosition / 100) * this.player.duration;
      }
    },

    playbackRate() {
      if (this.audioSource) {
        this.player.playbackRate = this.playbackRate;
      }
    },
  },

  methods: {
    ...mapMutations('audio', {
      setIsPlaying: SET_IS_PLAYING,
      setCurrentTime: SET_CURRENT_TIME,
      setCurrentPosition: SET_CURRENT_POSITION,
      setSource: SET_SOURCE,
      setPlaybackRate: SET_PLAYBACK_RATE,
    }),

    async deleteMessages() {
      if (!this.delete) {
        this.delete = true;
        setTimeout(() => {
          this.delete = false;
        }, 500);
        return;
      }

      this.loading = true;
      this.$store.commit(
        'ui/SET_CURRENT_DIALOG',
        { name: 'loaderDialog' },
        { root: true }
      );

      let selectedMessages = [...this.selectedMessages];

      for (let i = 0; i < selectedMessages.length; i++) {
        await this.$store
          .dispatch(
            'chat/delete_message',
            {
              chatId: selectedMessages[i].chatId,
              messageId: selectedMessages[i].messageId,
            },
            { root: true }
          )
          .then(res => {
            this.$store.commit(
              'chat/DELETE_FROM_SELECTED_MESSAGES',
              selectedMessages[i],
              { root: true }
            );

            if (this.selectedMessages.length === 0)
              this.$store.commit(
                'ui/REMOVE_ACTION_IF_LAST',
                'add-selected-message',
                { root: true }
              );
          })
          .catch(e => {
            console.error(e);
          });
      }

      this.loading = false;
      this.$store.commit('ui/SET_CURRENT_DIALOG', {}, { root: true });
    },

    forwardMessage() {
      if (!this.hasSelectedMessages) return;

      this.$store.commit(
        'ui/SET_CURRENT_DIALOG',
        {
          name: 'forwardMessageDialog',
          data: {
            message: this.selectedMessages[0],
          },
        },
        { root: true }
      );
    },

    clearSelected() {
      if (!this.hasSelectedMessages) return;

      this.$store.commit('chat/SET_SELECTED_MESSAGES', [], { root: true });
      this.$store.commit('ui/REMOVE_ACTION_IF_LAST', 'add-selected-message', {
        root: true,
      });
    },

    toggleSidebar() {
      this.showInfoBar = !this.showInfoBar;
    },

    startChat() {
      if (!this.timeout) {
        this.timeout = true;
        setTimeout(() => {
          this.timeout = false;
        }, 500);
        return;
      }

      this.loading = true;

      this.$store
        .dispatch(
          'chat/set_start_chat',
          { chatId: this.currentChat.chatId },
          { root: true }
        )
        .then(res => {
          this.loading = false;
        });
    },

    endChat() {
      if (!this.timeout) {
        this.timeout = true;
        setTimeout(() => {
          this.timeout = false;
        }, 500);
        return;
      }

      this.loading = true;

      this.$store
        .dispatch(
          'chat/set_end_chat',
          { chatId: this.currentChat.chatId },
          { root: true }
        )
        .then(res => {
          this.loading = false;
        });
    },

    playAudio() {
      this.setIsPlaying(true);
    },

    pauseAudio() {
      this.setIsPlaying(false);
    },

    closeAudioPlayer() {
      this.pauseAudio();
      this.setSource('');
    },

    onAudioTimeUpdate() {
      const currentTime = Math.floor(this.player.currentTime);
      if (this.$store.state.audio.currentTime !== currentTime) {
        this.setCurrentTime(currentTime);
      }
    },

    onAudioEnded() {
      this.setCurrentTime(0);
      this.pauseAudio();
    },

    onPlaybackRateChange(playbackRate) {
      this.setPlaybackRate(playbackRate);
    },
  },

  mounted() {
    this.player.addEventListener('timeupdate', this.onAudioTimeUpdate);
    this.player.addEventListener('ended', this.onAudioEnded);
  },

  beforeUnmount() {
    this.player.removeEventListener('timeupdate', this.onTimeUpdate);
    this.player.removeEventListener('ended', this.onAudioEnded);
  },
};
</script>

<style scoped lang="scss">
.supports {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;

  margin: -0.25rem;
}

::v-deep .v-toolbar__content {
  padding-left: 0;
  padding-right: 0;
}
</style>
