import io from 'socket.io-client';

// socket
class SocketController {
  constructor() {
    this.socket = io.connect(process.env.VUE_APP_SOCKET, {
      extraHeaders: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    });
  }
}

const socketController = new SocketController();
export default socketController;
