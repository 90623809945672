<template>
  <v-dialog persistent :value="show" :width="width">
    <v-card class="pt-2">
      <v-card-title>{{ title }}</v-card-title>
      <div class="d-flex flex-column pa-6">
        <slot></slot>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="$emit('close')">
            {{ closeButtonText }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'GenericDialog',

  props: {
    closeButtonText: {
      type: String,
      default() {
        return 'Закрити';
      },
    },
    show: {
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      default() {
        return '1200';
      },
    },
  },
};
</script>
