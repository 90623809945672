<template>
  <v-list-item
    @click="$emit('message-click', item.chatId, item.messageId)"
    :key="item.chatId + index"
    :class="{
      'support-on-chat': item.chatId === currentChatId,
      'is-client-last-message': item.supportId === null,
    }"
    class="pl-1"
  >
    <v-list-item-content>
      <div class="d-flex justify-space-between pl-1">
        <div
          v-if="item.chatId === currentChatId"
          style="position: absolute; top: 0; bottom: 0; left: 0; z-index: 1"
        >
          <div
            :class="currentMessageId === item.messageId ? 'purple' : 'blue'"
            style="height: 100%; width: 4px; border-radius: 2px"
          ></div>
        </div>

        <div class="d-flex" style="max-width: 210px">
          <div style="position: relative" class="mr-2">
            <span
              v-if="chat.isVIP"
              style="
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
              "
              >👑</span
            >
            <v-icon
              v-if="chat.blockedBy"
              color="red"
              style="
                position: absolute;
                z-index: 1;
                font-size: 44px;
                left: -1px;
                top: -1px;
              "
            >
              mdi-block-helper
            </v-icon>

            <v-avatar color="teal" size="42">
              <img v-if="chat.avatar" :src="blobContainerUrl + chat.avatar" />
              <span v-else class="white--text">{{
                chat.from.first_name.charAt(0).toUpperCase()
              }}</span>
            </v-avatar>
          </div>

          <div class="overflow-hidden">
            <div class="mb-1 font-weight-medium text-truncate">
              {{ getUserNameFromChat(chat) }}
            </div>
            <div class="text-truncate" style="max-width: 200px">
              {{
                item.supportId !== null ? 'Мен:' : `${chat.from.first_name}:`
              }}
              {{ item.text }}
            </div>
          </div>
        </div>

        <div>
          <div
            v-if="isToday(new Date(item.createdAt))"
            class="text-body-2"
            style="line-height: 1"
          >
            {{ formatTime(Math.round(item.createdAt)) }}
          </div>
          <div v-else class="text-body-2" style="line-height: 1">
            {{ formatDate(Math.round(item.createdAt)) }}
          </div>
        </div>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { formatDate, formatTime } from '@/lib/utils';

export default {
  name: 'SearchMessage',

  props: {
    currentChatId: Number,
    currentMessageId: Number,
    index: Number,
    item: Object,
    chat: Object,
    blobContainerUrl: String,
  },

  methods: {
    formatDate,
    formatTime,

    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    },

    getUserNameFromChat(chat) {
      let name = `${chat.from.first_name || ''} ${chat.from.last_name || ''}`;
      if (name.trim().length === 0) name = chat.from.username;

      return name.trim() || 'No name';
    },
  },
};
</script>
