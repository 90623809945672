<template>
  <router-view />
</template>

<script>
import msal from './lib/msalAuth';

export default {
  name: 'App',
  created() {
    this.setDarkMode();
    this.tokenRefresh();
    this.syncLocalStorageFeatures();
    this.initializeFeatureState();
  },
  methods: {
    async tokenRefresh() {
      const msalInstance = await msal.createMsalInstance();
      const account = msalInstance.getAllAccounts()[0];

      if (!account) {
        this.$store.commit('auth/REMOVE_CREDENTIALS');
      }
    },

    setDarkMode() {
      let item = localStorage.getItem('darkMode');
      let hasItem = item !== null;
      let darkMode = hasItem
        ? JSON.parse(item.replace(/"false"/g, false).replace(/"true"/g, true))
        : false;

      this.$vuetify.theme.dark = darkMode;
    },
    initializeFeatureState() {
      this.$store.commit('feature/INITIALIZE_STATE');
    },
    syncLocalStorageFeatures() {
      this.$store.commit('feature/SYNC_LOCAL_STORAGE');
    },
  },
};
</script>

<style lang="scss">
html {
  overflow-y: auto !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 0.6);
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(50, 50, 50, 0.2);
}
</style>
