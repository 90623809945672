const axios = require('axios');

const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const originPath = `${origin}/${prefix}/admins/supports`;
const routes = {
  get: {
    supports: originPath,
    clientVotes: `${originPath}/client-votes`,
  },

  post: {
    support: originPath,
  },

  put: {
    support: `${originPath}/{supportId}`,
  },

  delete: {
    support: `${originPath}/{supportId}`,
  },
};

exports.getRoutes = () => {
  return routes;
};

exports.getAll = () => {
  const url = routes.get.supports;
  return axios.get(url);
};

exports.getClientVotes = (query = {}) => {
  let params = Object.keys(query).length
    ? `?${new URLSearchParams(query).toString()}`
    : '';

  const url = routes.get.clientVotes + params;
  return axios.get(url);
};

exports.create = (supportData, text) => {
  const url = routes.post.support;
  return axios.post(url, supportData);
};

exports.edit = (supportId, supportData) => {
  const url = routes.put.support.replace('{supportId}', supportId);
  return axios.put(url, supportData);
};

exports.delete = supportId => {
  const url = routes.delete.support.replace('{supportId}', supportId);
  let data = { supportId };

  return axios.delete(url, { data });
};
