<template>
  <RecycleScroller
    class="scroll-container list pr-3"
    @scroll-end="loadMore"
    :items="items"
    :item-size="70"
  >
    <template v-slot="{ item }">
      <file-row
        v-if="
          item.type === 'document' && isRequiredFile(item.src, ['jpg', 'png'])
        "
        @scroll-to-message="() => scrollToMessage(item.messageId)"
      >
        <div class="document">
          <img
            :src="item.src"
            style="
              width: 4rem;
              max-height: 5rem;
              height: auto;
              padding: 0px 5px;
            "
            alt="Preview of image"
          />
          <div class="description">
            <a :href="item.src" target="_blank">
              {{ item.name }}
            </a>

            <div class="date text-caption grey--text">
              {{ getFormattedDateAndTime(item.createdAt) }}
            </div>
          </div>
        </div>
      </file-row>

      <file-row
        v-else-if="item.type === 'document'"
        @scroll-to-message="() => scrollToMessage(item.messageId)"
      >
        <div class="document">
          <v-icon class="mr-2">mdi-file-outline</v-icon>
          <div class="description">
            <a :href="item.src" target="_blank">{{ item.name }}</a>
            <div class="date text-caption grey--text">
              {{ getFormattedDateAndTime(item.createdAt) }}
            </div>
          </div>
        </div>
      </file-row>

      <file-row
        v-else-if="item.type === 'voice'"
        @scroll-to-message="() => scrollToMessage(item.messageId)"
      >
        <div class="voice">
          <div class="description">
            <voice-message :source="item.src" :duration="item.duration" />
            <div class="date text-caption grey--text">
              {{ getFormattedDateAndTime(item.createdAt) }}
            </div>
          </div>
        </div>
      </file-row>

      <div class="text-h6" v-else-if="item.month">
        {{ item.month }} <br />
        {{ item.year }}
        <v-divider />
      </div>
    </template>
  </RecycleScroller>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller_1_1_2';
import VoiceMessage from './VoiceMessage.vue';
import FileRow from './FileRow.vue';
import { formatDate, formatTime } from '@/lib/utils';

export default {
  name: 'FileList',

  components: {
    RecycleScroller,
    VoiceMessage,
    FileRow,
  },

  props: {
    items: Array,
    isLoading: Boolean,
    loadMore: Function,
  },

  methods: {
    isRequiredFile(filename, affordableExtensions) {
      return affordableExtensions.some(extension =>
        filename.toLowerCase().endsWith(extension)
      );
    },

    getFormattedDateAndTime(date) {
      const formattedDate = formatDate(date);
      const formattedTime = formatTime(date);
      return `${formattedDate} ${formattedTime}`;
    },

    scrollToMessage(messageId) {
      this.$emit('scroll-to-message', messageId);
    },
  },
};
</script>

<style lang="scss">
.scroll-container.list {
  .document {
    height: 50px;
    display: flex;
    align-items: center;

    .description {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      a {
        text-decoration: none;
      }
    }

    .v-icon.theme--dark + .description {
      a {
        color: #fff;
      }
    }

    .v-icon.theme--light + .description {
      a {
        color: #000;
      }
    }
  }

  .voice {
    width: 100%;

    .description {
      .date {
        text-align: right;
        margin-top: -25px;
        margin-right: 16px;
      }
    }
  }
}
</style>
