import { BLOB_CONTAINER_URL } from './const';

export const mapMediaMessagesToUnifiedMedia = (messages, type) =>
  messages.map(message => {
    const { fileId, fileName, originalName, duration } = message[type];
    const { createdAt, messageId } = message;

    return {
      id: fileId,
      createdAt,
      src: `${BLOB_CONTAINER_URL}${fileName}`,
      type,
      messageId,
      ...(originalName && { name: originalName }),
      ...(duration !== undefined && { duration }),
    };
  });
