<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="blue" dark elevation="0" v-bind="attrs" v-on="on"
        >Шаблони</v-btn
      >
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title class="flex-shrink-0">Шаблони</v-toolbar-title>

        <v-tabs
          v-model="tab"
          align-with-title
          dark
          center-active
          show-arrows
          active-class="white--text"
          class="main-tabs"
        >
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <template v-for="tab in tabs">
            <v-tab :key="tab.title" dark>{{ tab.title }}</v-tab>
          </template>
        </v-tabs>

        <v-spacer></v-spacer>
        <message-templates-create-dialog></message-templates-create-dialog>
      </v-toolbar>

      <v-toolbar v-if="subFolders.length" dark dense>
        <v-tabs
          v-model="subFoldersTab"
          align-with-title
          dark
          center-active
          show-arrows
        >
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <template v-for="(tab, index) in subFolders">
            <v-tab :key="index" dark>{{ tab }}</v-tab>
          </template>
        </v-tabs>
      </v-toolbar>

      <div class="pa-4">
        <div style="display: flex; flex-wrap: wrap; margin: -0.5rem">
          <div
            v-for="messageTemplate in messageTemplates"
            style="padding: 0.5rem; max-width: 25%; width: 100%"
          >
            <v-card
              @click="setMessageTemplate(messageTemplate)"
              @contextmenu="openMenu($event, messageTemplate)"
              ripple
              class="fill-height"
              hover
              width="100%"
            >
              <v-card-text>
                <div class="text--primary">
                  {{ messageTemplate.title.substring(0, 50) }}
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
    </v-card>

    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="editMessageTemplate" link>
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-pencil</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Редагувати</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="deleteMessageTemplate" link>
          <v-list-item-icon class="mr-4">
            <v-btn
              v-if="loading"
              :loading="loading"
              plain
              icon
              class="pa-0 ma-0"
              style="line-height: 1; height: 24px; width: 24px"
            >
              <v-icon>mdi-cached</v-icon>
            </v-btn>
            <v-icon v-else>mdi-delete</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Видалити</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <message-templates-edit-dialog
      ref="edit-dialog"
    ></message-templates-edit-dialog>
  </v-dialog>
</template>

<script>
import MessageTemplatesCreateDialog from '@/components/dialogs/MessageTemplatesCreateDialog';
import MessageTemplatesEditDialog from '@/components/dialogs/MessageTemplatesEditDialog';

export default {
  name: 'MessageTemplatesDialog',
  components: {
    MessageTemplatesCreateDialog,
    MessageTemplatesEditDialog,
  },

  data() {
    return {
      loading: false,
      delete: false,
      showMenu: true,
      menuMessageTemplate: null,
      x: 0,
      y: 0,
    };
  },

  watch: {
    showMenu(val) {
      val
        ? this.$store.commit('ui/ADD_ACTION', 'open-context-menu', {
            root: true,
          })
        : this.$store.commit('ui/REMOVE_ACTION_IF_LAST', 'open-context-menu', {
            root: true,
          });
    },
  },

  computed: {
    show: {
      get() {
        return (
          this.$store.state.ui.currentDialog.name ===
            'messageTemplatesDialog' ||
          this.$store.state.ui.currentDialog.name ===
            'messageTemplatesCreateDialog' ||
          this.$store.state.ui.currentDialog.name ===
            'messageTemplatesEditDialog'
        );
      },

      set(show) {
        if (show) {
          this.$store.commit(
            'ui/SET_CURRENT_DIALOG',
            { name: 'messageTemplatesDialog' },
            { root: true }
          );
          this.$store.commit('ui/ADD_ACTION', 'open-message-templates-dialog', {
            root: true,
          });
        }
      },
    },

    messageTemplates() {
      return this.$store.getters['messageTemplates/messageTemplates'];
    },

    tabs() {
      return this.$store.getters['messageTemplates/tabs'];
    },

    tab: {
      get() {
        return this.$store.state.messageTemplates.currentTabIndex;
      },

      set(val) {
        this.$store.commit('messageTemplates/SET_CURRENT_TAB', val, {
          root: true,
        });
      },
    },

    subFoldersTab: {
      get() {
        return this.$store.state.messageTemplates.currentSubFolderTabIndex;
      },

      set(val) {
        this.$store.commit(
          'messageTemplates/SET_CURRENT_SUB_FOLDERS_TAB',
          val,
          { root: true }
        );
      },
    },

    subFolders() {
      return this.tabs[this.tab] ? this.tabs[this.tab].subFolders : [];
    },

    lastAction() {
      return this.$store.getters['ui/lastAction'];
    },
  },

  created() {
    document.addEventListener('keydown', e => {
      e = e || window.event;
      //escape
      if (e.keyCode === 27) {
        if (this.$store.state.ui.actions.length > 0) {
          if (this.lastAction === 'open-context-menu') {
            this.closeMenu();
          }
        }
      }
    });
  },

  methods: {
    setMessageTemplate(messageTemplate) {
      this.close();

      if (messageTemplate?.files?.length > 0) {
        this.$emit('showSendTemplateDialog', {
          files: messageTemplate.files,
          text: messageTemplate.text,
          title: messageTemplate.title,
        });
        return;
      }

      setTimeout(() => {
        this.$store.commit('chat/SET_MESSAGE_INPUT', messageTemplate.text, {
          root: true,
        });
        document.getElementById('messageInput').focus();
      }, 200);
    },

    deleteMessageTemplate() {
      if (!this.menuMessageTemplate) return;

      if (!this.delete) {
        this.delete = true;
        setTimeout(() => {
          this.delete = false;
        }, 500);
        return;
      }

      this.loading = true;

      this.$store
        .dispatch(
          'messageTemplates/delete_message_template',
          this.menuMessageTemplate._id,
          { root: true }
        )
        .then(res => {
          this.closeMenu();
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    editMessageTemplate() {
      if (!this.menuMessageTemplate) return;

      this.$store.commit(
        'ui/SET_CURRENT_DIALOG',
        {
          name: 'messageTemplatesEditDialog',
          data: {
            messageTemplate: { ...this.menuMessageTemplate },
          },
        },
        { root: true }
      );

      this.closeMenu();
    },

    openMenu(e, messageTemplate) {
      e.preventDefault();

      if (this.showMenu) {
        this.closeMenu();
        return;
      }

      this.x = e.clientX;
      this.y = e.clientY;

      this.$nextTick(() => {
        this.menuMessageTemplate = messageTemplate;
        this.showMenu = true;
      });
    },

    closeMenu() {
      if (!this.showMenu) return;

      this.showMenu = false;
      this.menuMessageTemplate = null;
    },

    close() {
      this.$store.commit('ui/SET_CURRENT_DIALOG', {}, { root: true });
      this.$store.commit(
        'ui/REMOVE_ACTION_IF_LAST',
        'open-message-templates-dialog',
        { root: true }
      );
    },
  },
};
</script>

<style lang="scss">
.main-tabs {
  width: calc(100% - 211px);
}
</style>
