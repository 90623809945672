<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="label"
        :rules="rules"
        :value="value"
        append-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker :max="maxDate" :value="value" @input="handleInput" />
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',

  props: {
    label: {
      type: String,
      required: true,
    },
    maxDate: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      required: false,
    },
    value: {
      type: String,
    },
  },

  data() {
    return {
      open: false,
    };
  },

  methods: {
    handleInput(value) {
      this.open = false;
      this.$emit('input', value);
    },
  },
};
</script>
