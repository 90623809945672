<template>
  <div class="my-2 d-flex flex-column">
    <div class="body-1">{{ title }}</div>
    <v-autocomplete
      @change="value => $emit('change', value)"
      :disabled="disabled"
      :items="options"
      :value="value"
      chips
      clearable
      dense
      multiple
    />
    <v-divider />
  </div>
</template>

<script>
export default {
  name: 'AutocompleteFilter',

  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    title: String,
    value: Array,
    options: Array,
  },
};
</script>
