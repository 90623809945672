import { PublicClientApplication } from '@azure/msal-browser';

// MSAL Configuration
const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    authority: `${process.env.VUE_APP_CLOUD_INSTANCE}${process.env.VUE_APP_TENANT_ID}`,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

// Get msal instance function
const createMsalInstance = async () => new PublicClientApplication(msalConfig);

export default {
  createMsalInstance,
  msalConfig,
};
