import chatsApi from '@/api/admin/chats';
import {
  getExistenceByKeyValue,
  getObjectByKeyValue,
  getObjectIndexByKeyValue,
} from '@/lib/utils';
import Vue from 'vue';

const state = {
  chats: [],
};

const getters = {};

const mutations = {
  SET_CHATS(state, { chats }) {
    const chatIds = state.chats.map(chat => chat.chatId);
    chats.forEach(chat => {
      if (chatIds.indexOf(chat.chatId) < 0) state.chats.push(chat);
    });
  },

  SET_CURRENT_CHAT_ID(state, chatId) {
    state.currentChatId = chatId;
  },

  ADD_CHAT(state, chat) {
    state.chats.push(chat);
  },

  // SET_CHAT_COUNT(state, { groupName, count }) {
  //     if (state.counts.hasOwnProperty(groupName))
  //         state.counts[groupName] = count;
  // },

  SET_CHAT_COUNTS(state, counts) {
    Object.keys(state.counts).forEach(countKey => {
      state.counts[countKey] = counts[countKey];
    });
  },

  SET_UNREAD_MESSAGES_COUNT(state, { chatId, count }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    chat.unreadMessagesCount = count;
  },

  ADD_CHAT_MESSAGE(state, message) {
    let chat = getObjectByKeyValue(state.chats, message.chatId, 'chatId');
    if (chat) {
      let allMessages = [...chat.messages, message];
      chat.messages = allMessages.sort(
        (messageOne, messageTwo) => messageOne.createdAt - messageTwo.createdAt
      );
    }
  },

  EDIT_MESSAGE(state, fields) {
    let chat = getObjectByKeyValue(state.chats, fields.chatId, 'chatId');

    if (chat) {
      let messageIndex = getObjectIndexByKeyValue(
        chat.messages,
        fields.messageId,
        'messageId'
      );
      const removeReplyToMessageField = field => {
        for (let i = chat.messages.length - 1; i >= 0; i--) {
          let message = chat.messages[i];
          let isEditedMessage =
            message.replyToMessage &&
            message.replyToMessage.messageId === fields.messageId;

          if (isEditedMessage) Vue.delete(message.replyToMessage, field);
        }
      };
      const mediaProperties = [
        'photo',
        'document',
        'videoNote',
        'video',
        'voice',
      ];
      const findMediaType = message => {
        let messageKeys = Object.keys(message);

        for (let i = 0; i < messageKeys.length; i++) {
          if (mediaProperties.indexOf(messageKeys[i]) >= 0)
            return messageKeys[i];
        }

        return null;
      };
      const handleTypeChange = (currentMessage, editedMessage) => {
        const currentMessageMediaType = findMediaType(currentMessage);
        const editedMessageMediaType = findMediaType(editedMessage);

        if (!currentMessageMediaType || !editedMessageMediaType) return;

        if (currentMessageMediaType !== editedMessageMediaType) {
          delete chat.messages[messageIndex][currentMessageMediaType];
          removeReplyToMessageField(currentMessageMediaType);
        }
      };

      if (findMediaType(chat.messages[messageIndex])) {
        if (
          chat.messages[messageIndex].hasOwnProperty('text') &&
          Object.keys(fields).indexOf('text') < 0
        ) {
          delete chat.messages[messageIndex]['text'];
          removeReplyToMessageField('text');
        }
      }

      handleTypeChange(chat.messages[messageIndex], fields);

      Object.keys(fields).forEach(field => {
        Vue.set(chat.messages[messageIndex], field, fields[field]);
      });

      const messageContentFields = [
        'text',
        'photo',
        'document',
        'videoNote',
        'video',
        'voice',
      ];

      //set changed fields for repliedMessage after message removal
      for (let i = chat.messages.length - 1; i >= 0; i--) {
        let message = chat.messages[i];
        let isEditedMessage =
          message.replyToMessage &&
          message.replyToMessage.messageId === fields.messageId;

        if (isEditedMessage) {
          const fieldsToChange = Object.keys(fields).filter(
            field => messageContentFields.indexOf(field) >= 0
          );

          fieldsToChange.forEach(field => {
            Vue.set(message.replyToMessage, field, fields[field]);
          });
        }
      }
    }
  },

  DELETE_MESSAGE(state, { chatId, messageId }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) {
      let messageIndex = getObjectIndexByKeyValue(
        chat.messages,
        messageId,
        'messageId'
      );
      chat.messages.splice(messageIndex, 1);

      //set isRemoved for repliedMessage after message removal
      for (let i = chat.messages.length - 1; i >= 0; i--) {
        let message = chat.messages[i];
        let isRemovedMessage =
          message.replyToMessage &&
          message.replyToMessage.messageId === messageId;

        if (isRemovedMessage) message.replyToMessage.isRemoved = true;
      }
    }
  },

  SET_MESSAGE_COMMENT(state, { chatId, messageId, comment }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) {
      let messageIndex = getObjectIndexByKeyValue(
        chat.messages,
        messageId,
        'messageId'
      );
      Vue.set(chat.messages[messageIndex], 'comment', comment);
    }
  },

  SET_MESSAGE_COLOR(state, { chatId, messageId, color }) {
    let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');
    if (chat) {
      let messageIndex = getObjectIndexByKeyValue(
        chat.messages,
        messageId,
        'messageId'
      );
      Vue.set(chat.messages[messageIndex], 'color', color);
    }
  },

  SET_CHAT_MODE(state, mode = '') {
    if (Object.values(state.chatModes).indexOf(mode) >= 0)
      state.chatMode = mode;
  },

  SET_REPLY_TO_MESSAGE(state, message = {}) {
    if (Object.keys(message).length > 0) {
      let chat = getObjectByKeyValue(state.chats, message.chatId, 'chatId');
      message.from = chat.from;
    }

    state.replyToMessage = message;
  },

  ADD_SELECTED_MESSAGE(state, message) {
    let messageExists = getExistenceByKeyValue(
      state.selectedMessages,
      message._id,
      '_id'
    );
    if (!messageExists) state.selectedMessages.push(message);
  },

  DELETE_FROM_SELECTED_MESSAGES(state, message) {
    let messageIndex = getObjectIndexByKeyValue(
      state.selectedMessages,
      message._id,
      '_id'
    );
    if (messageIndex >= 0) state.selectedMessages.splice(messageIndex, 1);
  },

  SET_SELECTED_MESSAGES(state, messages = []) {
    state.selectedMessages = messages;
  },
};

const actions = {
  //Api calls
  get_chats({ commit, state }, payload = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        let chatsResponse = await chatsApi.getAll({
          limit: payload.limit || 20,
          skip: payload.skip || 0,
        });

        let chats = chatsResponse.data;
        let chatsWithMessages = chats.filter(chat => chat.messages.length > 0);
        let chatsWithNoMessages = chats.filter(
          chat => chat.messages.length === 0
        );

        if (chatsWithNoMessages.length)
          console.log('chatsWithNoMessages', chatsWithNoMessages);

        commit('SET_CHATS', { chats: chatsWithMessages });
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },

  //not used
  get_chat({ commit, state }, chatId) {
    return new Promise(async (resolve, reject) => {
      try {
        let chatResponse = await chatsApi.getOne(chatId);
        let chat = chatResponse.data;
        if (chat.messages && chat.messages.length === 0)
          throw 'Chat has no messages';

        commit('ADD_CHAT', chat);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },

  get_more_chat_messages({ state }, chatId) {
    return new Promise(async (resolve, reject) => {
      try {
        let chat = getObjectByKeyValue(state.chats, chatId, 'chatId');

        let messagesResponse = await messagesApi.getAll(chatId, {
          limit: 50,
          skip: chat.messages.length,
        });

        let messageIds = chat.messages.map(x => x.messageId);
        let newMessages = messagesResponse.data.filter(
          x => messageIds.indexOf(x.messageId) < 0
        );

        chat.messages = [...newMessages, ...chat.messages];

        resolve();
      } catch (e) {
        reject(e);
      }
    });
  },

  get_chat_counts({ commit }) {
    chatsApi.getCounts().then(res => {
      commit('SET_CHAT_COUNTS', res.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
